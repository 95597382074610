import React from "react";
import { WhmAlert, WhmBox, WhmTypography } from "@securitize/reactjs-whm";
import TranslationKeys from "../../../../contexts/I18nContext/translationKeys";
import { Translate } from "../../../Translation/Translate";
import { WhmCustomButton } from "../../../shared/WhmCustomButton";
import { useNavigate } from "react-router";
import { ReactComponent as WarningIcon } from "@securitize/assets-sec-ui/dist/icons/svg/warning.svg";
import { VERIFICATION_URL } from "../../routing-constants";

interface AccreditationAlertProps {
  currentPath: string;
}

export const AccreditationAlert: React.FC<AccreditationAlertProps> = ({
  currentPath,
}) => {
  const navigate = useNavigate();

  return (
    <WhmAlert
      severity="warning"
      sx={{
        marginBottom: "16px",
        padding: "6px 16px",
        ".MuiAlert-message": {
          width: "100%",
          padding: { xs: "8px 0", xl: "4px 0" },
        },
      }}
      icon={<WarningIcon width={22} height={22} fill="currentColor" />}
    >
      <WhmBox
        sx={{
          flexDirection: { xs: "column", xl: "row" },
          justifyContent: "space-between",
          display: "flex",
          gap: "8px",
        }}
      >
        <WhmTypography
          variant="body2"
          sx={{ alignSelf: { xs: "flex-start", xl: "center" } }}
        >
          <Translate
            translationKey={
              TranslationKeys.PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_ALERT_MESSAGE
            }
          />
        </WhmTypography>

        <WhmCustomButton
          color="secondary"
          size="small"
          sx={{ minWidth: "131px" }}
          variant={"contained"}
          onClick={() => navigate(VERIFICATION_URL(currentPath))}
        >
          <Translate
            translationKey={
              TranslationKeys.PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_ALERT_CTA
            }
          />
        </WhmCustomButton>
      </WhmBox>
    </WhmAlert>
  );
};
