import { forwardRef, useContext, useRef, useState } from "react";
import {
  WhmAvatar,
  WhmBox,
  WhmCircularProgress,
  WhmDivider,
  WhmListItem,
  WhmListItemAvatar,
  WhmListItemIcon,
  WhmMenu,
  WhmMenuItem,
  WhmStack,
  WhmTypography,
  WhmScopedThemeProvider,
  whmTheme,
} from "@securitize/reactjs-whm";
import { ReactComponent as IndividualAvatar } from "@securitize/assets-sec-ui/dist/icons/svg/individual-profile.svg";
import { ReactComponent as EntityAvatar } from "@securitize/assets-sec-ui/dist/icons/svg/entity.svg";
import { ReactComponent as Check } from "@securitize/assets-sec-ui/dist/icons/svg/check.svg";
import { ReactComponent as Add } from "@securitize/assets-sec-ui/dist/icons/svg/add.svg";
import { ReactComponent as Logout } from "@securitize/assets-sec-ui/dist/icons/svg/log-out.svg";
import useUserInvestorsQuery, {
  CalculatedVerificationStatus,
  UserInvestorsResponse,
} from "../../data/useUserInvestorsQuery";
import { I18nContext, translationKeys } from "../../contexts/I18nContext";
import { Translate } from "../Translation/Translate";
import { iconSmall } from "../shared/iconThemes";

function colorForStatus(status: CalculatedVerificationStatus) {
  switch (status) {
    case "verified":
      return "success.main";
    case "expired":
    case "not-verified":
    case "pending":
    case "updates-required":
    case "documents-expired":
      return "warning.main";
    case "blocked":
    case "service-denied":
    default:
      return "error.main";
  }
}

function UserCard({
  name,
  status,
  selected = false,
  investorType,
  onClick,
}: {
  name: string;
  investorType: "individual" | "entity";
  status: CalculatedVerificationStatus;
  selected?: boolean;
  onClick: () => void;
}) {
  return (
    <WhmMenuItem onClick={() => (selected ? null : onClick())}>
      <WhmListItemAvatar>
        <WhmAvatar
          sx={{
            background: "none",
            border: "1px solid",
            borderColor: colorForStatus(status),
          }}
        >
          {investorType === "individual" ? (
            <IndividualAvatar />
          ) : (
            <EntityAvatar />
          )}
        </WhmAvatar>
      </WhmListItemAvatar>
      <WhmStack
        direction="row"
        justifyContent="space-between"
        sx={{ overflow: "hidden", flexGrow: "1" }}
      >
        <WhmStack sx={{ overflow: "hidden" }}>
          <WhmTypography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {name}
          </WhmTypography>
          {status !== "verified" && (
            <WhmTypography color={colorForStatus(status)} variant="body2">
              <Translate
                translationKey={`Texts.user-menu-investor-status-${status}`}
              />
            </WhmTypography>
          )}
        </WhmStack>
      </WhmStack>
      {selected && (
        <WhmBox sx={{ margin: "0 0 0 10px" }}>
          <Check fill={whmTheme.palette.primary.main} fontSize="small" />
        </WhmBox>
      )}
    </WhmMenuItem>
  );
}

export function UserMenuContents({
  onCreateUser,
  onLogout,
  onSwitchUser,
  investors,
  currentInvestor,
}: {
  onCreateUser: () => void;
  onLogout: () => void;
  onSwitchUser: (user: string) => void;
  investors: UserInvestorsResponse["availableInvestors"];
  currentInvestor: string;
}) {
  return (
    <>
      {investors.map((investor) => (
        <UserCard
          key={investor.id}
          name={investor.displayName}
          status={investor.verification.calculatedStatus}
          investorType={investor.investorType}
          selected={investor.id === currentInvestor}
          onClick={() => onSwitchUser(investor.id)}
        />
      ))}
      <WhmDivider />
      <WhmMenuItem onClick={onCreateUser}>
        <WhmListItemIcon>
          <Add style={iconSmall} fill="currentColor" />
        </WhmListItemIcon>
        <WhmTypography>
          <Translate
            translationKey={translationKeys.USER_MENU_CREATE_ENTITY_ACCOUNT}
          />
        </WhmTypography>
      </WhmMenuItem>
      <WhmDivider />
      <WhmMenuItem onClick={onLogout}>
        <WhmListItemIcon>
          <Logout style={iconSmall} fill="currentColor" />
        </WhmListItemIcon>
        <WhmTypography>
          <Translate translationKey={translationKeys.LOGOUT} />
        </WhmTypography>
      </WhmMenuItem>
    </>
  );
}

const UserData = forwardRef(function UserDataComponent(
  {
    onClick,
    investorType,
    name,
    status,
    logo,
  }: {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    investorType: "individual" | "entity";
    name: string;
    status: CalculatedVerificationStatus;
    logo?: string;
  },
  ref,
) {
  const { getTranslation } = useContext(I18nContext);
  return (
    <WhmBox
      component="button"
      onClick={onClick}
      ref={ref}
      sx={{
        border: "none",
        background: "none",
        cursor: "pointer",
      }}
      data-testid="sid-mfe-user-menu"
    >
      <WhmStack spacing={2} direction="row" alignItems="center">
        <WhmStack alignItems="end">
          <WhmTypography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "328px",
              whiteSpace: "nowrap",
              display: {
                xs: "none",
                xl: "inline",
              },
            }}
          >
            {name}
          </WhmTypography>
          {status !== "verified" && (
            <WhmTypography
              noWrap
              color={colorForStatus(status)}
              variant="body2"
              sx={{
                display: {
                  xs: "none",
                  xl: "inline",
                },
              }}
            >
              <Translate
                translationKey={`Texts.user-menu-investor-status-${status}`}
              />
            </WhmTypography>
          )}
        </WhmStack>
        <WhmAvatar
          sx={{
            background: "#f9f9f9",
            border: "1px solid",
            borderColor: colorForStatus(status),
          }}
        >
          {logo ? (
            <img
              src={logo}
              alt={getTranslation(
                investorType === "individual"
                  ? translationKeys.INDIVIDUAL
                  : translationKeys.ENTITY,
              )}
            />
          ) : investorType === "individual" ? (
            <IndividualAvatar />
          ) : (
            <EntityAvatar />
          )}
        </WhmAvatar>
      </WhmStack>
    </WhmBox>
  );
});

export interface UserMenuProps {
  onCreateUser: () => void;
  onLogout: () => void;
  onSwitchUser: (user: string) => void;
  logo?: string;
}

export default function UserMenuContainer({
  onCreateUser,
  onLogout,
  onSwitchUser,
  logo,
}: UserMenuProps) {
  const ref = useRef();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAndCreateAccount = () => {
    setAnchorEl(null);
    onCreateUser();
  };

  const investorsQuery = useUserInvestorsQuery();

  if (investorsQuery.isPending)
    return (
      <WhmListItem>
        <WhmCircularProgress />
      </WhmListItem>
    );

  if (investorsQuery.isError)
    return (
      <WhmListItem>
        <WhmTypography color="error">
          <Translate
            translationKey={translationKeys.USER_MENU_ERROR_LOADING_INVESTORS}
          />
        </WhmTypography>
      </WhmListItem>
    );

  return (
    <WhmBox sx={{ display: "flex", justifyContent: "end" }}>
      <UserData
        onClick={handleClick}
        ref={ref}
        status={
          investorsQuery.data.currentInvestorData.verification.calculatedStatus
        }
        name={investorsQuery.data.currentInvestorData.displayName}
        investorType={investorsQuery.data.currentInvestorData.investorType}
        logo={logo}
      />
      <WhmScopedThemeProvider>
        <WhmMenu
          open={open}
          elevation={8}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          MenuListProps={{
            sx: {
              minWidth: "288px",
              maxWidth: "380px",
              maxHeight: "calc(100vh - 156px)",
            },
          }}
        >
          <UserMenuContents
            onCreateUser={handleCloseAndCreateAccount}
            onLogout={onLogout}
            onSwitchUser={onSwitchUser}
            investors={investorsQuery.data.availableInvestors}
            currentInvestor={investorsQuery.data.currentInvestor}
          />
        </WhmMenu>
      </WhmScopedThemeProvider>
    </WhmBox>
  );
}
