import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import {
  WhmBox,
  WhmTextField,
  whmTheme,
  WhmTooltip,
} from "@securitize/reactjs-whm";
import { Translate, TranslateHtml } from "../../Translation/Translate";
import { InfoCard } from "../shared/InfoCard";
import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { InvestorDto } from "../../../data/dtos/investor.dto";
import { CountryTextField } from "../shared/CountryTextField";
import { columnBox, textFieldTooltipBox, wrapBox } from "../shared/styles";
import { ReactComponent as InfoOutlined } from "@securitize/assets-sec-ui/dist/icons/svg/info.svg";
import { iconSmall } from "../../shared/iconThemes";

interface TaxJurisdictionCardProps {
  investorInfoQuery: UseQueryResult<InvestorDto, Error>;
  language: string;
}

export const TaxJurisdictionCard: React.FC<
  React.PropsWithChildren<TaxJurisdictionCardProps>
> = ({ investorInfoQuery, language }) => {
  return (
    <InfoCard
      title={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_TAX_CARD_TITLE
          }
        />
      }
      subtitle={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_TAX_CARD_SUBTITLE
          }
        />
      }
      footer={
        <TranslateHtml
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_TAX_CARD_FOOTER
          }
        />
      }
    >
      <WhmBox
        component="form"
        sx={{
          ...columnBox,
          marginTop: "32px",
          marginBottom: "24px",
        }}
        noValidate
        autoComplete="off"
      >
        <WhmBox
          sx={{
            ...wrapBox,
            gap: "16px",
          }}
        >
          <CountryTextField
            countryCode={investorInfoQuery.data?.details.tax[0]?.taxCountryCode}
            language={language}
            dataTestId={
              "profile-personal-information-tax-jurisdiction-country-field"
            }
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_TAX_RESIDENCE
                }
              />
            }
          />

          <WhmBox sx={textFieldTooltipBox}>
            <WhmTextField
              id="taxId"
              inputProps={{
                "data-test-id":
                  "profile-personal-information-tax-jurisdiction-tax-id-field",
              }}
              label={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_TAX_ID
                  }
                />
              }
              defaultValue={investorInfoQuery.data?.details.tax[0]?.taxId}
              disabled
              sx={{ flexGrow: 1, marginRight: "8px" }}
            />
            <WhmTooltip
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    ul: {
                      paddingLeft: "16px",
                      listStyleType: "unset",
                    },
                  },
                },
              }}
              data-test-id="profile-personal-information-tax-jurisdiction-tooltip"
              title={
                <TranslateHtml
                  translationKey={
                    TranslationKeys.PROFILE_PERSONAL_INFORMATION_TAX_CARD_TOOLTIP
                  }
                />
              }
            >
              <InfoOutlined
                style={iconSmall}
                fill={whmTheme.palette.action.disabled}
              />
            </WhmTooltip>
          </WhmBox>
        </WhmBox>
      </WhmBox>
    </InfoCard>
  );
};
