import {
  Theme,
  useMediaQuery,
  WhmBox,
  WhmCircularProgress,
  WhmDialogContent,
  WhmDialogTitle,
  WhmFormHelperText,
  WhmIconButton,
  WhmLink,
  WhmLoadingButton,
  WhmStack,
  WhmTextField,
  WhmTypography,
} from "@securitize/reactjs-whm";
import {
  ClipboardEvent,
  ChangeEvent,
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { I18nContext } from "../../contexts/I18nContext";
import translationKeys from "../../contexts/I18nContext/translationKeys";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { SnackbarContext } from "../shared/Snackbar";
import { Phone } from "./types";
import { useTimer } from "react-timer-hook";
import {
  useGenerateCodeMutation,
  useValidateCodeMutation,
} from "../../data/usePhoneVerification";
import { Translate, TranslateHtml } from "../Translation/Translate";
import { ReactComponent as ChevronLeft } from "@securitize/assets-sec-ui/dist/icons/svg/arrow-left.svg";
import { iconSmall } from "../shared/iconThemes";

type CodeStepFormType = {
  code: string;
};

export interface CodeStepProps {
  setStep: (step: "phone" | "code") => void;
  phone: Phone;
  onVerify: () => void;
}

const useValidationSchema = () => {
  const { getTranslation } = useContext(I18nContext);

  return Yup.object({
    code: Yup.string()
      .required(
        getTranslation(
          translationKeys.PHONE_VERIFICATION_CODE_INPUT_EMPTY_HELPER_TEXT,
        ),
      )
      .min(
        6,
        getTranslation(
          translationKeys.PHONE_VERIFICATION_CODE_INPUT_EMPTY_HELPER_TEXT,
        ),
      )
      .max(
        6,
        getTranslation(
          translationKeys.PHONE_VERIFICATION_CODE_INPUT_EMPTY_HELPER_TEXT,
        ),
      ),
  });
};

export default function CodeStep({ setStep, phone, onVerify }: CodeStepProps) {
  const codeLength = 6;
  const requestCodeTimerInSeconds = 60;
  const { getTranslation } = useContext(I18nContext);
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const [verificationCode, setVerificationCode] = useState<string[]>(
    Array(codeLength).fill(""),
  );
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [touchedInputs, setTouchedInputs] = useState<boolean[]>(
    Array(codeLength).fill(false),
  );
  const validationSchema = useValidationSchema();
  const [showRequestButton, setShowRequestButton] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);
  const generateCode = useGenerateCodeMutation();
  const validateCode = useValidateCodeMutation();

  const handleInputChange = async (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    formik: FormikProps<CodeStepFormType>,
  ) => {
    const code = verificationCode;
    code[index] = e.target.value;
    setVerificationCode(code);

    const joinedCode = code.join("");
    await formik.setFieldValue("code", joinedCode);

    // Code input focus updates
    if (e.target.value) {
      // Move focus to the next input if the value is not empty and index is less than 5
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      // Move focus to the previous input if the value is empty and index is greater than 0
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  // Set focus to the first input on mount
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  // Code inputs error logic. Cannot use Formik directly for these.
  const handleInputBlur = (index: number) => {
    const newTouchedInputs = [...touchedInputs];
    newTouchedInputs[index] = true;
    setTouchedInputs(newTouchedInputs);
    validateCode.reset();
  };
  const isAnyInputInvalid = () => {
    return touchedInputs.some(
      (touched, index) => touched && verificationCode[index]?.length !== 1,
    );
  };
  const handlePaste = async (
    e: ClipboardEvent<HTMLDivElement>,
    formik: FormikProps<CodeStepFormType>,
  ) => {
    const pastedData = e.clipboardData.getData("Text").slice(0, 6);
    const codeArray = pastedData.split("");
    setVerificationCode(codeArray);
    await formik.setFieldValue("code", pastedData);
    codeArray.forEach((char, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].value = char;
      }
    });
  };

  // These extra handler is in place to support pasting the code in the first input
  // But not from the clipboard, as apparently iOS is doing with its "from message" paste functionality
  const handleInput = async (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formik: FormikProps<CodeStepFormType>,
  ) => {
    const inputValue = e.target.value.slice(0, 6);
    const codeArray = inputValue.split("");
    setVerificationCode(codeArray);
    await formik.setFieldValue("code", inputValue);
    codeArray.forEach((char, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].value = char;
      }
    });
  };

  const cleanCodeInputs = () => {
    setVerificationCode(Array(codeLength).fill(""));
    setTouchedInputs(Array(codeLength).fill(false));
    validateCode.reset();
    inputRefs.current.forEach((input, _) => {
      input.value = "";
    });
  };

  // Countdown
  const expiryTimestamp = useMemo(() => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + requestCodeTimerInSeconds);
    return time;
  }, []);

  const { seconds, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => setShowRequestButton(true),
  });

  const handleRequestNewCode = () => {
    generateCode.mutate(
      {
        phone: phone,
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: getTranslation(
              translationKeys.PHONE_VERIFICATION_CODE_STEP_CODE_RESENT,
            ),
            severity: "success",
          });
          cleanCodeInputs();
        },
        onError: () => {
          showSnackbar({
            message: getTranslation(
              translationKeys.PHONE_VERIFICATION_ERROR_GENERATING_CODE,
            ),
            severity: "error",
          });
          setStep("phone");
        },
        onSettled: () => {
          const newExpiryTimestamp = new Date();
          newExpiryTimestamp.setSeconds(
            newExpiryTimestamp.getSeconds() + requestCodeTimerInSeconds,
          );
          restart(newExpiryTimestamp);
          setShowRequestButton(false); // Hide the button and show the timer again
        },
      },
    );
  };

  const maskedNumber = `+${phone.code} ${phone.number.slice(0, -4) || ""}****`;

  // Styling
  const buttonStyle = {
    svg: {
      display: "block !important",
    },
    marginBottom: "8px",
    "&.MuiLoadingButton-loading": {
      color: "black",
      opacity: "0.3",
      span: {
        color: "black",
      },
    },
    marginTop: {
      xs: "auto",
      sm: "auto",
      lg: "auto",
      xl: "24px",
    },
    alignSelf: {
      lg: "flex-end",
      xl: "flex-end",
    },
  };

  return (
    <Formik<CodeStepFormType>
      initialValues={{
        code: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        validateCode.mutate(
          {
            code: values.code,
            phone: phone,
          },
          {
            onSuccess: () => {
              showSnackbar({
                message: getTranslation(
                  translationKeys.PHONE_VERIFICATION_NUMBER_VERIFIED,
                ),
                severity: "success",
              });

              onVerify();
            },
          },
        );
      }}
    >
      {(formik) => (
        <WhmBox
          sx={{
            width: { xl: "444px" },
            display: "flex",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <WhmBox sx={{ display: "flex", alignItems: "center" }}>
            {showRequestButton && (
              <WhmIconButton
                aria-label="close"
                onClick={() => setStep("phone")}
                sx={{
                  color: "#0A1828",
                  marginLeft: { xs: "16px", lg: "24px" },
                }}
              >
                <ChevronLeft style={iconSmall} />
              </WhmIconButton>
            )}
            <WhmDialogTitle
              id="modal-title"
              variant="h4"
              sx={{
                "&#modal-title": {
                  paddingLeft: showRequestButton
                    ? "6px !important"
                    : "24px !important",
                },
              }}
            >
              <Translate
                translationKey={translationKeys.PHONE_VERIFICATION_TITLE}
              />
            </WhmDialogTitle>
          </WhmBox>

          <WhmDialogContent
            className="custom-padding"
            sx={{
              "&.custom-padding": {
                padding: {
                  sm: "8px 16px !important",
                  lg: "8px 24px !important",
                },
              },
            }}
          >
            <WhmStack sx={{ height: { xl: "auto", sm: "100%", xs: "100%" } }}>
              <WhmTypography id="code-step-subtitle" variant="body1">
                <TranslateHtml
                  translationKey={
                    translationKeys.PHONE_VERIFICATION_CODE_STEP_SUBTITLE
                  }
                  parameters={{ phone: maskedNumber }}
                />
              </WhmTypography>

              <WhmBox
                id={"code-inputs-with-error"}
                mb={3}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignSelf={"center"}
              >
                <WhmBox
                  id={"code-inputs"}
                  mt={5}
                  display={"flex"}
                  gap={"8px"}
                  justifyContent={"center"}
                >
                  {Array.from({ length: codeLength }, (_, index) => (
                    <WhmTextField
                      variant="outlined"
                      key={`code-input-${index}`}
                      error={isAnyInputInvalid() || validateCode.isError}
                      sx={{
                        minWidth: { xs: "40px", sm: "40px", lg: "56px" },
                        width: { xs: "40px", sm: "40px", lg: "56px" },
                        minHeight: { xs: "40px", sm: "40px", lg: "56px" },
                        height: { xs: "40px", sm: "40px", lg: "56px" },
                        boxSizing: "initial !important",
                      }}
                      inputProps={{
                        maxLength: 1,
                        pattern: "[0-9]*",
                        inputMode: "numeric",
                        style: {
                          textAlign: "center",
                          padding: mobile ? "8.5px" : undefined,
                        },
                      }}
                      onChange={(e) => {
                        void (async () => {
                          await handleInputChange(e, index, formik);
                        })();
                      }}
                      onBlur={() => handleInputBlur(index)}
                      inputRef={(el: HTMLInputElement) => {
                        inputRefs.current[index] = el;
                      }}
                      onPaste={
                        index === 0 ? (e) => handlePaste(e, formik) : undefined
                      }
                      onInput={
                        index === 0
                          ? (e: ChangeEvent<HTMLInputElement>) =>
                              handleInput(e, formik)
                          : undefined
                      }
                    />
                  ))}
                </WhmBox>
                {(isAnyInputInvalid() || validateCode.isError) && (
                  <WhmFormHelperText error>
                    {validateCode.isError
                      ? getTranslation(
                          translationKeys.PHONE_VERIFICATION_CODE_INPUT_INVALID_HELPER_TEXT,
                        )
                      : formik.errors.code}
                  </WhmFormHelperText>
                )}
              </WhmBox>

              <WhmBox sx={{ alignSelf: "center", marginBottom: "20px" }}>
                {generateCode.isPending ? (
                  <WhmCircularProgress
                    size={"16px"}
                    sx={{
                      display: "block",
                      svg: {
                        display: "block !important",
                      },
                    }}
                  />
                ) : showRequestButton ? (
                  <WhmLink
                    onClick={handleRequestNewCode}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                      fontSize: "12px",
                      color: "#0283A8 !important",
                    }}
                  >
                    <Translate
                      translationKey={
                        translationKeys.PHONE_VERIFICATION_CODE_STEP_NEW_CODE_REQUEST_BUTTON
                      }
                    />
                  </WhmLink>
                ) : (
                  <WhmTypography
                    id="code-step-caption"
                    variant="caption"
                    sx={{
                      opacity: "0.6",
                      background: "#f7f7f7",
                      padding: "6px 4px",
                    }}
                  >
                    <TranslateHtml
                      translationKey={
                        translationKeys.PHONE_VERIFICATION_CODE_STEP_NEW_CODE_REQUEST
                      }
                      parameters={{
                        time:
                          seconds === 0
                            ? `00:${requestCodeTimerInSeconds}`
                            : `00:${seconds.toString().padStart(2, "0")}`,
                      }}
                    />
                  </WhmTypography>
                )}
              </WhmBox>
              <WhmTypography
                id="modal-description"
                variant="body2"
                sx={{
                  color: "rgba(10,24,40,0.7)",
                  b: { fontWeight: "500 !important" },
                  a: {
                    textDecoration: "underline !important",
                    color: "#0283A8",
                  },
                }}
              >
                <TranslateHtml
                  translationKey={
                    translationKeys.PHONE_VERIFICATION_CODE_STEP_FOOTER
                  }
                />
              </WhmTypography>

              <WhmLoadingButton
                variant="contained"
                loading={validateCode.isPending}
                color="primary"
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                type={"" as any}
                onClick={() => void formik.submitForm()}
                disabled={
                  isAnyInputInvalid() || verificationCode.join("").length !== 6
                }
                sx={buttonStyle}
              >
                <Translate
                  translationKey={
                    translationKeys.PHONE_VERIFICATION_CODE_STEP_CTA_BUTTON
                  }
                />
              </WhmLoadingButton>
            </WhmStack>
          </WhmDialogContent>
        </WhmBox>
      )}
    </Formik>
  );
}
