import {
  WhmCard,
  WhmTypography,
  whmTheme,
  WhmStack,
} from "@securitize/reactjs-whm";
import React, { ReactNode } from "react";

interface InfoCardProps {
  title: ReactNode;
  subtitle: ReactNode;
  footer?: ReactNode;
  smallPadding?: boolean;
  children?: React.ReactNode;
  action?: React.ReactNode;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  title,
  subtitle,
  footer,
  smallPadding,
  children,
  action,
}) => {
  const padding = smallPadding ? "16px" : { xs: "16px", xl: "24px" };
  return (
    <WhmCard variant={"outlined"} sx={{ padding, width: "100%" }}>
      <WhmStack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: children ? "16px" : undefined,
        }}
      >
        <WhmStack>
          <WhmTypography variant="subtitle1">{title}</WhmTypography>
          <WhmTypography
            variant="body2"
            color={whmTheme.palette.text.secondary}
          >
            {subtitle}
          </WhmTypography>
        </WhmStack>
        {action}
      </WhmStack>
      {children}
      {footer && (
        <WhmTypography
          variant="body2"
          sx={{
            a: {
              textDecoration: "underline !important",
              color: whmTheme.palette.primary.main,
            },
          }}
        >
          {footer}
        </WhmTypography>
      )}
    </WhmCard>
  );
};
