// TextField Styles:
import { whmTheme } from "@securitize/reactjs-whm";

export const textFieldStyle = {
  flexGrow: { xl: 1 },
  minWidth: {
    xs: "100%",
    sm: "100%",
    md: "100%",
    lg: "100%",
    xl: 209,
  },
  flexBasis: "100%",
  color: whmTheme.palette.text.disabled,
  textarea: {
    overflow: "auto",
    scrollbarWidth: "thin",
    msOverflowStyle: "none",
  },
  "& textarea:hover::-webkit-scrollbar": {
    opacity: 1,
  },
};

export const placeholderTextFieldStyle = {
  ...textFieldStyle,
  display: { xs: "none", sm: "none", md: "none", lg: "none", xl: "block" },
};

// Box Styles:
export const columnBox = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
};

export const wrapBox = {
  display: "flex",
  width: "100%",
  flexWrap: { xs: "wrap", xl: "nowrap" },
};

export const textFieldTooltipBox = {
  ...textFieldStyle,
  display: "flex",
  alignItems: "center",
};

export const responsiveBox = {
  flexDirection: { xs: "column", xl: "row" },
  display: "flex",
};
