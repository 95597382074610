import { WhmInputAdornment, whmTheme } from "@securitize/reactjs-whm";
import { translationKeys } from "../../../../contexts/I18nContext";
import { Translate, TranslateHtml } from "../../../Translation/Translate";
import { SettingsTextFieldCard } from "./SettingsFieldCard";

export default function SettingsPhoneNumberField({
  phone,
}: {
  phone?: { code: string; number: string };
}) {
  return (
    <SettingsTextFieldCard
      title={
        <Translate
          translationKey={
            translationKeys.PROFILE_SETTINGS_PHONE_NUMBER_CARD_TITLE
          }
        />
      }
      subtitle={
        <Translate
          translationKey={
            translationKeys.PROFILE_SETTINGS_PHONE_NUMBER_CARD_SUBTITLE
          }
        />
      }
      footer={
        <TranslateHtml
          translationKey={
            translationKeys.PROFILE_SETTINGS_PHONE_NUMBER_CARD_FOOTER
          }
        />
      }
      label={
        <Translate
          translationKey={
            translationKeys.PROFILE_SETTINGS_PHONE_NUMBER_CARD_FIELD_LABEL
          }
        />
      }
      value={phone?.number}
      dataId={"profile-settings-phone-number-field"}
      startAdornment={
        phone?.code ? (
          <WhmInputAdornment
            position="start"
            sx={{
              lineHeight: "normal",
              ".MuiTypography-root": {
                color: whmTheme.palette.text.disabled,
              },
            }}
          >
            {phone.code.startsWith("+") ? phone.code : `+${phone.code}`}
          </WhmInputAdornment>
        ) : null
      }
    />
  );
}
