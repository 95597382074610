import React from "react";
import {
  styled,
  WhmBox,
  WhmCard,
  WhmCardContent,
  WhmSkeleton,
} from "@securitize/reactjs-whm";

const StyledWhmSkeleton = styled(WhmSkeleton)`
  flex-grow: 1;
  min-width: 100%;
  @media (min-width: 992px) {
    min-width: 209px;
  }
`;

const TripleSkeleton: React.FC = () => (
  <WhmBox
    sx={{
      display: "flex",
      flexDirection: "row",
      marginBottom: "16px",
      gap: "16px",
      flexWrap: { xs: "wrap", xl: "nowrap" },
    }}
  >
    <StyledWhmSkeleton variant="rounded" height={56} />
    <StyledWhmSkeleton variant="rounded" height={56} />
    <StyledWhmSkeleton variant="rounded" height={56} />
  </WhmBox>
);

export const LoadingCard: React.FC = () => (
  <WhmCard
    id="tab-content-skeleton"
    variant={"outlined"}
    data-test-id="profile-personal-information-skeleton-card"
    style={{
      display: "flex",
      marginTop: "16px",
    }}
  >
    <WhmCardContent
      style={{
        width: "100%",
        padding: "24px",
      }}
    >
      <div
        id="card-header-skeleton"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "32px",
        }}
      >
        <WhmSkeleton variant="rounded" width={120} height={20} />
        <WhmSkeleton variant="rounded" width={"100%"} height={20} />
      </div>

      <div
        id="card-content-skeleton"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TripleSkeleton />
        <TripleSkeleton />

        <WhmSkeleton variant="rounded" width={"100%"} height={28} />
      </div>
    </WhmCardContent>
  </WhmCard>
);
