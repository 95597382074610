import { WhmBox, WhmTextField } from "@securitize/reactjs-whm";
import { InfoCard } from "../../shared/InfoCard";
import React, { ReactNode } from "react";
import { textFieldStyle } from "../../shared/styles";

interface SettingsFieldCardProps {
  title: ReactNode;
  subtitle: ReactNode;
  value: string | undefined;
  label: ReactNode;
  dataId: string;
  footer?: ReactNode;
  startAdornment?: ReactNode;
}

export const SettingsTextFieldCard: React.FC<
  React.PropsWithChildren<SettingsFieldCardProps>
> = ({ title, subtitle, value, label, dataId, footer, startAdornment }) => {
  return (
    <InfoCard title={title} subtitle={subtitle} footer={footer} smallPadding>
      <WhmBox
        component="form"
        sx={{
          marginTop: "32px",
          marginBottom: "24px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        noValidate
        autoComplete="off"
      >
        <WhmTextField
          id="dataId"
          inputProps={{
            "data-test-id": dataId,
          }}
          label={label}
          defaultValue={value}
          sx={textFieldStyle}
          disabled
          InputProps={{
            startAdornment: value !== undefined ? startAdornment : undefined,
          }}
        />
      </WhmBox>
    </InfoCard>
  );
};
