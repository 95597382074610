import { WhmInputAdornment, whmTheme } from "@securitize/reactjs-whm";
import { translationKeys } from "../../../../contexts/I18nContext";
import { Translate, TranslateHtml } from "../../../Translation/Translate";
import { SettingsTextFieldCard } from "./SettingsFieldCard";
import { ReactComponent as EmailIcon } from "@securitize/assets-sec-ui/dist/icons/svg/email.svg";

export default function SettingsEmailField({ email }: { email: string }) {
  return (
    <SettingsTextFieldCard
      title={
        <Translate
          translationKey={translationKeys.PROFILE_SETTINGS_EMAIL_CARD_TITLE}
        />
      }
      subtitle={
        <Translate
          translationKey={translationKeys.PROFILE_SETTINGS_EMAIL_CARD_SUBTITLE}
        />
      }
      footer={
        <TranslateHtml
          translationKey={translationKeys.PROFILE_SETTINGS_EMAIL_CARD_FOOTER}
        />
      }
      label={
        <Translate
          translationKey={
            translationKeys.PROFILE_SETTINGS_EMAIL_CARD_FIELD_LABEL
          }
        />
      }
      value={email}
      dataId={"profile-settings-email-field"}
      startAdornment={
        <WhmInputAdornment position="start">
          <EmailIcon
            width={24}
            height={24}
            fill={whmTheme.palette.action.disabled}
          />
        </WhmInputAdornment>
      }
    />
  );
}
