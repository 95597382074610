import {
  WhmDialogContent,
  WhmDialogTitle,
  WhmInputAdornment,
  WhmLoadingButton,
  WhmStack,
  WhmTextField,
  WhmTypography,
  whmTheme,
} from "@securitize/reactjs-whm";
import React, { useContext } from "react";
import { I18nContext } from "../../contexts/I18nContext";
import translationKeys from "../../contexts/I18nContext/translationKeys";
import { WhmBox } from "@securitize/reactjs-whm/dist/components";
import intlTelInput from "intl-tel-input/intlTelInputWithUtils";
import { getDialCodeByCountryCode } from "../../helpers/phoneHelper";
import { Formik, FormikProps } from "formik";
import { Phone } from "./types";
import * as Yup from "yup";
import { useGenerateCodeMutation } from "../../data/usePhoneVerification";
import { Translate, TranslateHtml } from "../Translation/Translate";
import { SnackbarContext } from "../shared/Snackbar";

type PhoneStepFormType = {
  phone: string;
};

export interface PhoneStepProps {
  phone?: Phone;
  countryCode: string;
  setPhone: (phone: Phone) => void;
  setStep: (step: "phone" | "code") => void;
}

const useValidationSchema = (countryCode: string) => {
  const { getTranslation } = useContext(I18nContext);

  return Yup.object({
    phone: Yup.string()
      .required(
        getTranslation(
          translationKeys.PHONE_VERIFICATION_PHONE_INPUT_EMPTY_HELPER_TEXT,
        ),
      )
      .test(
        "Is-valid",
        getTranslation(
          translationKeys.PHONE_VERIFICATION_PHONE_INPUT_INVALID_HELPER_TEXT,
        ),
        (value) => intlTelInput.utils!.isValidNumber(value, countryCode),
      ),
  });
};

export default function PhoneStep({
  phone,
  countryCode,
  setPhone,
  setStep,
}: PhoneStepProps) {
  const { getTranslation } = useContext(I18nContext);
  const validationSchema = useValidationSchema(countryCode);
  const { showSnackbar } = useContext(SnackbarContext);
  const generateCode = useGenerateCodeMutation();

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formik: FormikProps<PhoneStepFormType>,
  ) => {
    const { value } = e.target;
    setPhone({
      code: getDialCodeByCountryCode(countryCode) || "",
      number: value,
      fullNumber: intlTelInput.utils!.formatNumber(value, countryCode),
    });

    await formik.setFieldValue("phone", value);
    formik.handleChange(e);
  };

  const getCleanedPhone = (phoneValue: string) => {
    const formattedPhone = intlTelInput.utils!.formatNumber(
      phoneValue,
      countryCode,
    );
    const dialCode = getDialCodeByCountryCode(countryCode) || "";
    return formattedPhone.replace(`+${dialCode}`, "");
  };

  // Styles
  const buttonStyle = {
    svg: {
      display: "block !important",
    },
    marginBottom: "8px",
    "&.MuiLoadingButton-loading": {
      color: "black",
      opacity: "0.3",
      span: {
        color: "black",
      },
    },
    marginTop: {
      xs: "auto",
      sm: "auto",
      lg: "auto",
      xl: "24px",
    },
    alignSelf: {
      lg: "flex-end",
      xl: "flex-end",
    },
  };

  return (
    <Formik<PhoneStepFormType>
      initialValues={{
        phone: phone?.number || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const phoneObj = {
          code: getDialCodeByCountryCode(countryCode) || "",
          number: getCleanedPhone(values.phone),
          fullNumber: intlTelInput.utils!.formatNumber(
            values.phone,
            countryCode,
          ),
        };

        setPhone(phoneObj);

        generateCode.mutate(
          {
            phone: phoneObj,
          },
          {
            onSuccess: () => {
              setStep("code");
            },
            onError: () => {
              showSnackbar({
                message: getTranslation(
                  translationKeys.PHONE_VERIFICATION_ERROR_GENERATING_CODE,
                ),
                severity: "error",
              });
            },
          },
        );

        return;
      }}
    >
      {(formik) => (
        <WhmBox
          sx={{
            width: { xl: "444px" },
            display: "flex",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <WhmDialogTitle
            className="custom-padding"
            sx={{
              "&.custom-padding": {
                padding: {
                  sm: "16px !important",
                  lg: "24px !important",
                },
              },
            }}
            data-test-id="modal-title"
            variant="h4"
          >
            <Translate
              translationKey={translationKeys.PHONE_VERIFICATION_TITLE}
            />
          </WhmDialogTitle>
          <WhmDialogContent
            className="custom-padding"
            sx={{
              "&.custom-padding": {
                padding: {
                  xs: "8px 16px !important",
                  sm: "8px 16px !important",
                  lg: "8px 24px !important",
                },
              },
            }}
          >
            <WhmStack sx={{ height: { xs: "100%", sm: "100%", xl: "auto" } }}>
              <WhmTypography id="modal-description" variant="body1">
                <Translate
                  translationKey={
                    translationKeys.PHONE_VERIFICATION_PHONE_STEP_SUBTITLE
                  }
                />
              </WhmTypography>

              <WhmTextField
                label={getTranslation(
                  translationKeys.PHONE_VERIFICATION_PHONE_INPUT_LABEL,
                )}
                name="phone"
                value={formik.values.phone}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  void (async () => {
                    await handleChange(e, formik);
                  })();
                }}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                placeholder={intlTelInput.utils!.getExampleNumber(
                  countryCode,
                  true,
                  2,
                )}
                type="tel"
                inputProps={{
                  "data-test-id": "outlined-start-adornment",
                }}
                sx={{
                  width: "100%",
                  marginTop: "24px",
                  marginBottom: "20px",
                  boxSizing: "initial !important",
                }}
                InputProps={{
                  startAdornment: (
                    <WhmInputAdornment
                      position="start"
                      sx={{ color: "black", opacity: "0.8" }}
                    >
                      +{getDialCodeByCountryCode(countryCode)}
                    </WhmInputAdornment>
                  ),
                }}
              />

              <WhmTypography
                data-test-id="modal-description-consent"
                variant="body2"
                sx={{
                  color: whmTheme.palette.text.secondary,
                }}
              >
                <Translate
                  translationKey={
                    translationKeys.PHONE_VERIFICATION_PHONE_STEP_FOOTER_CONSENT
                  }
                />
              </WhmTypography>

              <WhmTypography
                data-test-id="modal-description-footer"
                variant="body2"
                sx={{
                  marginTop: "24px",
                  color: whmTheme.palette.text.secondary,
                  b: { fontWeight: "500 !important" },
                  a: {
                    textDecoration: "underline !important",
                    color: whmTheme.palette.primary.main,
                  },
                }}
              >
                <TranslateHtml
                  translationKey={
                    translationKeys.PHONE_VERIFICATION_PHONE_STEP_FOOTER
                  }
                />
              </WhmTypography>

              <WhmLoadingButton
                variant="contained"
                color="primary"
                loading={generateCode.isPending}
                sx={buttonStyle}
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                type={"" as any}
                onClick={() => void formik.submitForm()}
              >
                <Translate
                  translationKey={
                    translationKeys.PHONE_VERIFICATION_PHONE_STEP_CTA_BUTTON
                  }
                />
              </WhmLoadingButton>
            </WhmStack>
          </WhmDialogContent>
        </WhmBox>
      )}
    </Formik>
  );
}
