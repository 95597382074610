import {
  WhmSnackbar,
  useMediaQuery,
  Theme,
  WhmAlert,
} from "@securitize/reactjs-whm";
import { useContext } from "react";
import { SnackbarContext } from "./SnackbarContext";

export interface SnackbarProps {
  isOpen: boolean;
  severity?: "error" | "warning" | "info" | "success";
  message?: React.ReactNode;
  onClose?: (event: React.SyntheticEvent<any> | Event, reason: string) => void;
  callback?: () => void;
}

const Snackbar = () => {
  const {
    snackbarProps: { isOpen, severity, message, onClose },
  } = useContext(SnackbarContext);
  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl"),
  );

  return (
    <WhmSnackbar
      open={isOpen}
      onClose={onClose}
      autoHideDuration={8000}
      anchorOrigin={{
        vertical: isMobileOrTablet ? "top" : "bottom",
        horizontal: "center",
      }}
    >
      <WhmAlert
        severity={severity}
        variant="filled"
        icon={false}
        sx={(theme) =>
          severity === "info"
            ? { backgroundColor: theme.palette.secondary.main }
            : {}
        }
      >
        {message}
      </WhmAlert>
    </WhmSnackbar>
  );
};

export default Snackbar;
