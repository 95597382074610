const defaultDateFormatOptions: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "short",
  year: "numeric",
};

/**
 * Formats a date string to a more readable format using "en" or "es" language.
 * @param {string} date - The date string to format.
 * @param language - the language string for formatting (e.g., 'en', 'es').
 * @returns {string} - The formatted date string.
 */
export function formatDateString(date: string, language: string): string {
  return new Date(date)
    .toLocaleDateString(
      language === "en" ? "en-US" : "es-ES",
      defaultDateFormatOptions,
    )
    .replace(",", "");
}

/**
 * Converts a Unix timestamp in seconds to an ISO string.
 * @param {number} timestamp - The Unix timestamp in seconds.
 * @returns {string} - The ISO string representation of the date.
 */
export function convertUnixTimestampToISOString(timestamp: number): string {
  return new Date(timestamp * 1000).toISOString();
}
