import TranslationKeys from "../../../../contexts/I18nContext/translationKeys";
import { Translate, TranslateHtml } from "../../../Translation/Translate";
import React, { useMemo } from "react";
import { InvestmentProfileTabCard } from "../InvestmentProfileTabCard";
import {
  InvestmentProfileDto,
  SuitabilityStatuses,
} from "../../../../data/dtos/investment-profile.dto";
import { SuitabilityButton } from "./SuitabilityButton";

interface SuitabilityCardProps {
  investmentProfileData?: InvestmentProfileDto;
  currentPath: string;
}

export const SuitabilityCard: React.FC<SuitabilityCardProps> = ({
  investmentProfileData,
  currentPath,
}) => {
  const suitabilityButton: React.ReactNode = useMemo(() => {
    if (
      investmentProfileData &&
      investmentProfileData.suitabilityStatus === SuitabilityStatuses.NONE
    ) {
      return <SuitabilityButton currentPath={currentPath} />;
    } else {
      return null;
    }
  }, [investmentProfileData, currentPath]);

  return (
    <InvestmentProfileTabCard
      title={
        <Translate
          translationKey={TranslationKeys.PROFILE_INVESTMENT_PROFILE_CARD_TITLE}
        />
      }
      subtitle={
        <TranslateHtml
          translationKey={
            TranslationKeys.PROFILE_INVESTMENT_PROFILE_CARD_SUBTITLE
          }
        />
      }
      button={suitabilityButton}
      suitabilityStatus={
        investmentProfileData?.suitabilityStatus ?? SuitabilityStatuses.NONE
      }
    />
  );
};
