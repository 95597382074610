import { WhmStack } from "@securitize/reactjs-whm";
import { SettingsLoadingPage } from "../loading/SettingsLoadingPage";
import { SettingsSecurityCard } from "./SettingsSecurityCard";
import { useGetSettingsQuery } from "../../../../data/useProfile";
import { SettingsInternalProps } from "./SettingsInternalProps";
import SettingsEmailField from "./SettingsEmailField";
import SettingsLanguageField from "./SettingsLanguageField";
import SettingsPhoneNumberField from "./SettingsPhoneNumberField";
import SettingsDownloadInfoCard from "./SettingsDownloadInfoCard";
import { SettingsDevicesCard } from "./SettingsDevicesCard";
import { ErrorPage } from "../../shared/ErrorPage";
import useUserInfo from "../../../../hooks/useUserInfo";
import SettingsDeleteCard from "./SettingsDeleteCard";

export default function SettingsMobile({
  isLoading,
  mfa,
  onChangePassword,
  language,
  onChangeLanguage,
  sessions,
}: SettingsInternalProps) {
  const settingsQuery = useGetSettingsQuery();
  const userInfo = useUserInfo();

  // Main UI
  if (settingsQuery.isPending || isLoading || sessions.isLoading) {
    return <SettingsLoadingPage />;
  }

  if (settingsQuery.isError || sessions.isError) {
    return (
      <ErrorPage
        errorCode={
          settingsQuery.isError
            ? settingsQuery.error.correlationId
            : sessions.correlationId
        }
        onRefresh={
          settingsQuery.isError ? settingsQuery.refetch : sessions.onRefresh
        }
      />
    );
  }

  return (
    <>
      <WhmStack>
        <WhmStack marginTop="20px" gap="20px">
          <SettingsEmailField email={settingsQuery.data.email} />
          <SettingsPhoneNumberField phone={settingsQuery.data.phone} />
          <SettingsSecurityCard
            onMfa={mfa.onMfa}
            hasMfa={mfa.hasMfa}
            onChangePassword={onChangePassword}
          />
          <SettingsDevicesCard
            activeUserSession={sessions.activeUserSession}
            onDeleteSession={sessions.onDeleteSession}
            userSessions={sessions.userSessions}
          />
          <SettingsLanguageField
            currentLanguage={language}
            onChangeLanguage={onChangeLanguage}
          />
          <SettingsDownloadInfoCard />
          {userInfo.role !== "viewer" && <SettingsDeleteCard />}
        </WhmStack>
      </WhmStack>
    </>
  );
}
