import config from "./config";
import Cookies from "js-cookie";
import { useMutation, useQuery } from "@tanstack/react-query";
import { InvestorDto } from "./dtos/investor.dto";
import { InvestmentProfileDto } from "./dtos/investment-profile.dto";
import { ProfileStatusDto } from "./dtos/profile-status.dto";
import { SettingsDto } from "./dtos/settings.dto";
import { ResponseError } from "./types/responseError";

export const useGetInvestorInformationQuery = () =>
  useQuery<InvestorDto, ResponseError>({
    queryKey: ["investor-information"],
    queryFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/profile/information`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new ResponseError(
          "Error fetching investor information",
          response,
        );
      }

      return response.json() as Promise<InvestorDto>;
    },
  });

export const useGetInvestmentProfileQuery = () =>
  useQuery<InvestmentProfileDto, ResponseError>({
    queryKey: ["investment-profile"],
    queryFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/profile/investment-profile`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new ResponseError("Error fetching investment profile", response);
      }

      return response.json() as Promise<InvestmentProfileDto>;
    },
  });

export const useGetSettingsQuery = () =>
  useQuery<SettingsDto, ResponseError>({
    queryKey: ["settings"],
    queryFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/profile/settings`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new ResponseError("Error fetching settings", response);
      }

      return response.json() as Promise<SettingsDto>;
    },
  });

export const useGetPendingActionsQuery = () =>
  useQuery({
    queryKey: ["pending-actions"],
    queryFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/profile/pending-actions`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new Error("Error fetching investment profile");
      }

      return response.json() as Promise<ProfileStatusDto>;
    },
  });

export const useDownloadInfoMutation = () =>
  useMutation<void, ResponseError>({
    mutationFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/profile/request-data`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
          method: "POST",
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new ResponseError("Error requesting data download", response);
      }
    },
  });

export const useDeleteInvestorMutation = () =>
  useMutation<{ accountDisabled: boolean }, ResponseError>({
    mutationFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/investor/request-deletion`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
          method: "POST",
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new ResponseError("Error requesting data download", response);
      }

      return response.json();
    },
  });
