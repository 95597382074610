import React from "react";
import { WhmCard, WhmCardContent, WhmSkeleton } from "@securitize/reactjs-whm";

export const InvestmentLoadingCard: React.FC = () => (
  <WhmCard
    id="tab-content-skeleton"
    variant={"outlined"}
    data-test-id="profile-personal-information-skeleton-card"
    sx={{
      display: "flex",
      width: "100%",
    }}
  >
    <WhmCardContent
      sx={{
        width: "100%",
        padding: "16px",
      }}
    >
      <div
        id="card-info-skeleton"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "16px",
        }}
      >
        <WhmSkeleton variant="rounded" width={120} height={20} />
        <WhmSkeleton variant="rounded" width={"100%"} height={20} />
        <WhmSkeleton variant="rounded" width={200} height={20} />
      </div>

      <WhmSkeleton
        variant="rounded"
        width={125}
        height={36}
        sx={{ marginTop: "8px" }}
      />
    </WhmCardContent>
  </WhmCard>
);
