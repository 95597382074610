export const BASE_PATH = "/registration";

export const REGISTRATION_STEP_PATHS = {
  name: "name",
  phone: "phone",
  accountType: "account-type",
  entityInfo: "entity-info",
} as const;

export type RegistrationSteps =
  (typeof REGISTRATION_STEP_PATHS)[keyof typeof REGISTRATION_STEP_PATHS];
