import { createContext } from "react";
import { Router } from "@remix-run/router";

export interface MFEContextType {
  mixPanel: Record<string, (arg?: unknown) => void>;
  router: Router;
}

export const MFEContext = createContext({} as MFEContextType);

type Props = {
  mixPanel: Record<string, (arg?: unknown) => void>;
  router: Router;
  children: React.ReactNode;
};

const MFEProvider = ({ children, ...props }: Props) => {
  return <MFEContext.Provider value={props}>{children}</MFEContext.Provider>;
};

export default MFEProvider;
