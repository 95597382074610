import { useContext, useEffect } from "react";
import { WhmStack, WhmTypography } from "@securitize/reactjs-whm";
import translationKeys from "../../../contexts/I18nContext/translationKeys";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { Translate } from "../../Translation/Translate";
import { ErrorPage } from "../shared/ErrorPage";

const CONTENT_DOM_ID = "_sec_fe_authorized_members_content";

interface AuthorizedMembersCardProps {
  isPendingActionsLoading: boolean;
}

export const AuthorizedMembersCard = ({
  isPendingActionsLoading,
}: AuthorizedMembersCardProps) => {
  const { authorizedMembers } = useContext(ProfileContext);

  useEffect(
    () =>
      !isPendingActionsLoading
        ? authorizedMembers.onRenderAuthorizedMembersUI(CONTENT_DOM_ID, false)
        : undefined,
    [authorizedMembers, isPendingActionsLoading],
  );

  if (isPendingActionsLoading) return null;

  return (
    <WhmStack sx={{ padding: "16px 0px" }}>
      {authorizedMembers.isError ? (
        <ErrorPage
          errorCode={authorizedMembers.correlationId}
          onRefresh={() => {
            authorizedMembers.onRenderAuthorizedMembersUI(CONTENT_DOM_ID, true);
          }}
        />
      ) : (
        <>
          <WhmTypography variant="h4">
            <Translate
              translationKey={translationKeys.PROFILE_AUTHORIZED_MEMBERS_TITLE}
            />
          </WhmTypography>
          <WhmTypography variant="body2" color="GrayText">
            <Translate
              translationKey={
                translationKeys.PROFILE_AUTHORIZED_MEMBERS_SUBTITLE
              }
            />
          </WhmTypography>
        </>
      )}

      {/*The element where the page is loaded is always in the DOM but hidden when error page is shown*/}
      <WhmStack
        sx={{
          marginTop: "20px",
          display: authorizedMembers.isError ? "none" : "flex",
        }}
        id={CONTENT_DOM_ID}
      />
    </WhmStack>
  );
};
