import React from "react";
import { WhmBox } from "@securitize/reactjs-whm";
import { SettingsTopLoadingCard } from "./SettingsTopLoadingCard";
import { SettingsSecurityLoadingCard } from "./SettingsSecurityLoadingCard";
import { SettingsDevicesLoadingCard } from "./SettingsDevicesLoadingCard";
import { SettingsSmallLoadingCard } from "./SettingsSmallLoadingCard";
import { SettingsLanguageLoadingCard } from "./SettingsLanguageLoadingCard";
import { responsiveBox } from "../../shared/styles";

const loadingBoxStyle = {
  ...responsiveBox,
  paddingTop: "20px",
  gap: "20px",
};
export const SettingsLoadingPage: React.FC = () => (
  <>
    <WhmBox sx={loadingBoxStyle}>
      <SettingsTopLoadingCard />
      <SettingsTopLoadingCard />
    </WhmBox>

    <WhmBox sx={loadingBoxStyle}>
      <SettingsSecurityLoadingCard />
      <SettingsDevicesLoadingCard />
    </WhmBox>

    <WhmBox sx={loadingBoxStyle}>
      <WhmBox
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column" },
          gap: "20px",
          order: { xs: 1, xl: 0 },
        }}
      >
        <SettingsSmallLoadingCard />
        <SettingsSmallLoadingCard />
      </WhmBox>

      <SettingsLanguageLoadingCard />
    </WhmBox>
  </>
);
