import { createContext, useCallback, useState } from "react";
import { SnackbarProps } from "./Snackbar";

interface SnackbarContextProps {
  snackbarProps: SnackbarProps;
  showSnackbar: (props: Omit<SnackbarProps, "isOpen">) => void;
  hideSnackbar: () => void;
}

export const SnackbarContext = createContext<SnackbarContextProps>(
  {} as SnackbarContextProps,
);

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
    isOpen: false,
    severity: "info",
    message: "",
    onClose: () => {},
  });

  const hideSnackbar = useCallback(() => {
    setSnackbarProps((prevProps) => ({ ...prevProps, isOpen: false }));
  }, []);

  const defaultOnClose = useCallback(
    (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      hideSnackbar();
    },
    [hideSnackbar],
  );

  const showSnackbar = useCallback(
    (props: Omit<SnackbarProps, "isOpen">) => {
      setSnackbarProps({
        isOpen: true,
        ...props,
        // eslint-disable-next-line react/prop-types
        onClose: props.onClose ?? defaultOnClose,
      });
    },
    [defaultOnClose],
  );

  return (
    <SnackbarContext.Provider
      value={{ snackbarProps, showSnackbar, hideSnackbar }}
    >
      {children}
    </SnackbarContext.Provider>
  );
}
