import {
  WhmFormControlLabel,
  WhmRadio,
  WhmRadioGroup,
} from "@securitize/reactjs-whm";
import { translationKeys } from "../../../../contexts/I18nContext";
import { Translate } from "../../../Translation/Translate";
import { InfoCard } from "../../shared/InfoCard";

export default function SettingsLanguageField({
  currentLanguage,
  onChangeLanguage,
}: {
  currentLanguage: string;
  onChangeLanguage: (language: string) => void;
}) {
  return (
    <InfoCard
      title={
        <Translate
          translationKey={translationKeys.PROFILE_SETTINGS_LANGUAGE_CARD_TITLE}
        />
      }
      subtitle={
        <Translate
          translationKey={
            translationKeys.PROFILE_SETTINGS_LANGUAGE_CARD_SUBTITLE
          }
        />
      }
      smallPadding
    >
      <WhmRadioGroup
        row
        value={currentLanguage}
        onChange={(e) => onChangeLanguage(e.target.value)}
      >
        <WhmFormControlLabel
          sx={{ margin: "0 16px 0 4px" }}
          value="en"
          control={<WhmRadio />}
          label="English"
        />
        <WhmFormControlLabel
          sx={{ margin: "0 16px 0 4px" }}
          value="es"
          control={<WhmRadio />}
          label="Español"
        />
      </WhmRadioGroup>
    </InfoCard>
  );
}
