import { convertUnixTimestampToISOString } from "../../utils/dateUtils";

export const DeviceTypes = {
  DESKTOP: "laptop",
  MOBILE: "mobile",
  TABLET: "tablet",
};

export type DeviceType = (typeof DeviceTypes)[keyof typeof DeviceTypes];

export type SessionAgent = {
  os: string;
  device: string;
  deviceType: DeviceType;
};

export type Session = {
  sessionId: string;
  lastActive: string;
  expires: string;
  agent: SessionAgent;
};

export declare class KeycloakSessionDto {
  id: string;
  started: number;
  lastAccess: number;
  expires: number;
  browser: string;
  current?: boolean;
  os: string;
  osVersion: string;
  device: string;
  mobile: boolean;
}

export function mapKeycloakSessionToSession(
  activeSession: KeycloakSessionDto,
): Session {
  const sessionAgent: SessionAgent = {
    os: activeSession.os,
    device: activeSession.device,
    deviceType: activeSession.mobile ? DeviceTypes.MOBILE : DeviceTypes.DESKTOP,
  };

  return {
    sessionId: activeSession.id,
    lastActive: convertUnixTimestampToISOString(activeSession.lastAccess),
    expires: convertUnixTimestampToISOString(activeSession.expires),
    agent: sessionAgent,
  };
}
