import config from "./config";
import Cookies from "js-cookie";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import { KeycloakSessionDto } from "./dtos/sessions.dto";
import { ResponseError } from "./types/responseError";

export const useGetActiveSessionsQuery = () =>
  useQuery<KeycloakSessionDto[], ResponseError>({
    queryKey: ["active-sessions"],
    queryFn: async () => {
      const response = await fetch(
        `${config.AUTH_GW_BASE_URL}/sessions/active`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new ResponseError("Error fetching active sessions", response);
      }

      return response.json() as Promise<KeycloakSessionDto[]>;
    },
  });

export const useDeleteSessionMutation = (
  options?: UseMutationOptions<KeycloakSessionDto[], Error, string>,
) => {
  return useMutation({
    mutationFn: async (sessionId: string) => {
      const response = await fetch(
        `${config.AUTH_GW_BASE_URL}/sessions/active/${sessionId}`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
            "Content-Type": "application/json",
          },
          method: "DELETE",
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new Error("Error deleting session");
      }
      return response.json() as Promise<KeycloakSessionDto[]>;
    },
    ...options,
  });
};
