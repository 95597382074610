import { createContext } from "react";
import { UserInfo } from "../types/UserInfo";

export interface UserContextType {
  userInfo: UserInfo;
}

export const UserContext = createContext({} as UserContextType);

type Props = {
  userInfo: UserInfo;
  children: React.ReactNode;
};

const UserProvider = ({ userInfo, children }: Props) => {
  return (
    <UserContext.Provider value={{ userInfo }}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
