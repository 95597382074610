import { useCallback, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FunctionMicroFrontend } from "@securitize/reactjs-mfe";
import { ProfileContext } from "../../contexts/ProfileContext";
import { UserContext } from "../../contexts/UserContext";
import { MFEContext } from "../../contexts/MFEContext";
import { ErrorPage } from "../../components/Profile/shared/ErrorPage";

const ENTRY_POINT_URL = process.env.REACT_APP_TA_TAX_CENTER_MFE_ENTRY_POINT_URL;

const TaxCenter = ({
  isPendingActionsLoading,
}: {
  isPendingActionsLoading: boolean;
}) => {
  const { language } = useContext(ProfileContext);
  const { userInfo } = useContext(UserContext);
  const { mixPanel, router } = useContext(MFEContext);
  const navigate = useNavigate();
  const location = useLocation();

  const renderParams = useMemo(
    () => ({
      language,
      userInfo,
      MixPanel: mixPanel,
      basePath: "/profile",
    }),
    [language, userInfo],
  );

  const mountParams = useMemo(
    () => ({
      onNavigate: ({ pathname: nextPathname }: { pathname: string }) => {
        if (location.pathname !== nextPathname) {
          navigate(nextPathname);
        }
      },
      basePath: "/profile",
    }),
    [], // we don't use list here, because mount params are executed only once
  );

  const afterMount = useCallback(
    ({
      onParentNavigate,
    }: {
      onParentNavigate: (_: {
        pathname: string;
        search: string;
        state: unknown;
      }) => void;
    }) => {
      router.subscribe((subscriber) =>
        onParentNavigate({
          pathname: subscriber.location.pathname,
          search: subscriber.location.search,
          state: subscriber.location.state,
        }),
      );
    },
    [], // we don't use list here, because mount params are executed only once
  );

  if (isPendingActionsLoading) return null;

  return (
    <FunctionMicroFrontend
      entryPointUrl={ENTRY_POINT_URL ?? ""}
      exposedPath="./TaxCenterApp"
      name="taTaxCenterFe"
      afterMount={afterMount}
      mountParams={mountParams}
      renderParams={renderParams}
      errorContent={<ErrorPage />}
    />
  );
};

export default TaxCenter;
