import { createContext } from "react";
import { SessionAgent, Session } from "../data/dtos/sessions.dto";

export interface ProfileContextType {
  basePath: string;
  language: string;
  mfa: {
    hasMfa: boolean;
    onMfa: () => void;
  };
  onChangePassword: () => void;
  onLogout: () => void;
  onChangeLanguage: (language: string) => void;
  sessions: {
    userSessions: Session[];
    activeUserSession: SessionAgent;
    onDeleteSession: (id: string) => void;
    isError: boolean;
    isLoading: boolean;
    onRefresh: () => void;
    correlationId?: string;
  };
  isKeycloakEnabled: boolean;
  authorizedMembers: {
    onRenderAuthorizedMembersUI: (
      domElementId: string,
      refresh: boolean,
    ) => void;
    correlationId?: string;
    isError: boolean;
  };
}

export const ProfileContext = createContext({} as ProfileContextType);

type ProfileProviderProps = {
  basePath: string;
  language: string;
  mfa: {
    hasMfa: boolean;
    onMfa: () => void;
  };
  onLogout: () => void;
  onChangePassword: () => void;
  onChangeLanguage: (language: string) => void;
  sessions: {
    userSessions: Session[];
    activeUserSession: SessionAgent;
    onDeleteSession: (id: string) => void;
    isError: boolean;
    isLoading: boolean;
    onRefresh: () => void;
    correlationId?: string;
  };
  isKeycloakEnabled: boolean;
  authorizedMembers: {
    onRenderAuthorizedMembersUI: (
      domElementId: string,
      refresh: boolean,
    ) => void;
    correlationId?: string;
    isError: boolean;
  };

  children: React.ReactNode;
};

export default function ProfileProvider({
  mfa,
  onChangePassword,
  sessions,
  children,
  language,
  onChangeLanguage,
  authorizedMembers,
  isKeycloakEnabled,
  basePath,
  onLogout,
}: ProfileProviderProps) {
  return (
    <ProfileContext.Provider
      value={{
        mfa,
        onChangePassword,
        language,
        onLogout,
        onChangeLanguage,
        isKeycloakEnabled,
        sessions,
        authorizedMembers,
        basePath,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}
