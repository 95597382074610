import { Theme, useMediaQuery } from "@securitize/reactjs-whm";
import SettingsDesktop from "./components/SettingsDesktop";
import SettingsMobile from "./components/SettingsMobile";
import { useContext } from "react";
import { ProfileContext } from "../../../contexts/ProfileContext";
import {
  useDeleteSessionMutation,
  useGetActiveSessionsQuery,
} from "../../../data/useAuth";
import { mapKeycloakSessionToSession } from "../../../data/dtos/sessions.dto";
import { useQueryClient } from "@tanstack/react-query";

interface KeycloakSettingsProps {
  isPendingActionsLoading: boolean;
}

export default function KeycloakSettings({
  isPendingActionsLoading,
}: KeycloakSettingsProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl"),
  );

  const queryClient = useQueryClient();
  const sessionsQuery = useGetActiveSessionsQuery();
  const deleteSessionMutation = useDeleteSessionMutation({
    onSuccess: (data) => {
      // Update sessionsQuery.data with the new data from the mutation response
      queryClient.setQueryData(["active-sessions"], data);
    },
  });

  const { mfa, onChangePassword, language, onChangeLanguage } =
    useContext(ProfileContext);

  const keycloakActiveSession = sessionsQuery.data?.find(
    (session) => session.current,
  );

  const activeSession = keycloakActiveSession
    ? mapKeycloakSessionToSession(keycloakActiveSession).agent
    : undefined;
  const sessions = sessionsQuery.data
    ?.filter((session) => !session.current)
    .map(mapKeycloakSessionToSession);

  const handleDeleteSession = (id: string) => {
    deleteSessionMutation.mutate(id);
  };

  const props = {
    isLoading: isPendingActionsLoading || sessionsQuery.isLoading,
    mfa,
    onChangePassword,
    language,
    onChangeLanguage,
    sessions: {
      userSessions: sessions,
      activeUserSession: activeSession,
      onDeleteSession: handleDeleteSession,
      isError: sessionsQuery.isError,
      isLoading: sessionsQuery.isLoading,
      onRefresh: sessionsQuery.refetch,
      correlationId: sessionsQuery.error?.correlationId,
    },
  };

  return isMobile ? (
    <SettingsMobile {...props} />
  ) : (
    <SettingsDesktop {...props} />
  );
}
