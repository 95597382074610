import {
  WhmChip,
  WhmDivider,
  WhmStack,
  whmTheme,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { InfoCard } from "../../shared/InfoCard";
import { WhmCustomButton } from "../../../shared/WhmCustomButton";
import { translationKeys } from "../../../../contexts/I18nContext";
import { Translate, TranslateHtml } from "../../../Translation/Translate";
import { WhmResponsiveStack } from "../../../shared/WhmResponsiveStack";

interface SettingsSecurityCardProps {
  onMfa: () => void;
  onChangePassword: () => void;
  hasMfa: boolean;
}

export const SettingsSecurityCard = ({
  onMfa,
  hasMfa,
  onChangePassword,
}: SettingsSecurityCardProps) => {
  return (
    <InfoCard
      title={
        <Translate
          translationKey={translationKeys.PROFILE_SETTINGS_SECURITY_CARD_TITLE}
        />
      }
      subtitle={
        <Translate
          translationKey={
            translationKeys.PROFILE_SETTINGS_SECURITY_CARD_SUBTITLE
          }
        />
      }
      smallPadding
    >
      <WhmStack
        gap="16px"
        sx={{
          marginTop: "16px",
          width: "100%",
        }}
      >
        <WhmStack gap="8px">
          <WhmResponsiveStack gap="8px">
            <WhmStack
              sx={{
                width: "100%",
                gap: "4px",
              }}
            >
              <WhmTypography
                variant="body2"
                color={whmTheme.palette.text.primary}
              >
                <Translate
                  translationKey={
                    translationKeys.PROFILE_SETTINGS_SECURITY_CARD_2FA_TITLE
                  }
                />
              </WhmTypography>
              <WhmTypography
                variant="body2"
                color={whmTheme.palette.text.secondary}
              >
                <Translate
                  translationKey={
                    translationKeys.PROFILE_SETTINGS_SECURITY_CARD_2FA_SUBTITLE
                  }
                />
              </WhmTypography>
            </WhmStack>
            {hasMfa ? (
              <WhmChip
                color="success"
                sx={{
                  alignSelf: { xs: "flex-start", xl: "center" },
                }}
                label={
                  <Translate
                    translationKey={
                      translationKeys.PROFILE_SETTINGS_SECURITY_CARD_2FA_ENABLED
                    }
                  />
                }
              />
            ) : (
              <WhmCustomButton
                variant="contained"
                color="primary"
                onClick={onMfa}
                data-test-id="profile-settings-2fa-cta"
                sx={{
                  minWidth: "fit-content",
                  alignSelf: { xs: "flex-start", xl: "center" },
                }}
              >
                <Translate
                  translationKey={
                    translationKeys.PROFILE_SETTINGS_SECURITY_CARD_2FA_CTA
                  }
                />
              </WhmCustomButton>
            )}
          </WhmResponsiveStack>
          {hasMfa && (
            <WhmTypography
              variant="body2"
              color={whmTheme.palette.text.primary}
              sx={{
                a: {
                  textDecoration: "underline !important",
                  color: whmTheme.palette.primary.main,
                },
              }}
            >
              <TranslateHtml
                translationKey={
                  translationKeys.PROFILE_SETTINGS_SECURITY_CARD_2FA_REQUEST_DISABLE
                }
              />
            </WhmTypography>
          )}
        </WhmStack>

        <WhmDivider />

        <WhmResponsiveStack gap="8px">
          <WhmStack direction="column" gap="4px">
            <WhmTypography
              variant="body2"
              color={whmTheme.palette.text.primary}
            >
              <Translate
                translationKey={
                  translationKeys.PROFILE_SETTINGS_SECURITY_CARD_PASSWORD_TITLE
                }
              />
            </WhmTypography>
            <WhmTypography
              variant="body2"
              color={whmTheme.palette.text.secondary}
            >
              <Translate
                translationKey={
                  translationKeys.PROFILE_SETTINGS_SECURITY_CARD_PASSWORD_SUBTITLE
                }
              />
            </WhmTypography>
          </WhmStack>
          <WhmCustomButton
            variant={"outlined"}
            color="primary"
            onClick={onChangePassword}
            data-test-id="profile-settings-2fa-cta"
            sx={{
              minWidth: "fit-content",
              alignSelf: { xs: "flex-start", xl: "center" },
            }}
          >
            <Translate
              translationKey={
                translationKeys.PROFILE_SETTINGS_SECURITY_CARD_PASSWORD_CTA
              }
            />
          </WhmCustomButton>
        </WhmResponsiveStack>
      </WhmStack>
    </InfoCard>
  );
};
