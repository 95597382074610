import { useMutation } from "@tanstack/react-query";
import config from "./config";
import Cookies from "js-cookie";
import { Phone } from "../components/PhoneVerificationModal/types";

export const useGenerateCodeMutation = () => {
  return useMutation({
    mutationFn: async (phone: { phone: Phone }) => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v2/investor/otp/generate`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(phone),
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new Error("Error generating OTP code");
      }
    },
  });
};

export const useValidateCodeMutation = () => {
  return useMutation({
    mutationFn: async (data: { phone: Phone; code: string }) => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v2/investor/otp/verify`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        },
      );

      if (!response.ok) {
        console.error(await response.text());
        throw new Error("Error validating OTP code");
      }
    },
  });
};
