import { InfoCard } from "../../shared/InfoCard";
import translationKeys from "../../../../contexts/I18nContext/translationKeys";
import { Translate, TranslateHtml } from "../../../Translation/Translate";
import {
  WhmButton,
  WhmDialog,
  WhmDialogActions,
  WhmDialogContent,
  WhmDialogTitle,
  WhmIconButton,
  WhmLoadingButton,
  WhmStack,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { useDeleteInvestorMutation } from "../../../../data/useProfile";
import { useContext, useState } from "react";
import { SnackbarContext } from "../../../shared/Snackbar";
import useUserInfo from "../../../../hooks/useUserInfo";
import { ProfileContext } from "../../../../contexts/ProfileContext";
import closeIcon from "@securitize/assets-sec-ui/dist/icons/svg/exit.svg";
import ErrorSnackbar from "../../../shared/ErrorSnackbar";

export default function SettingsDeleteCard() {
  const { onLogout } = useContext(ProfileContext);
  const deleteInvestorMutation = useDeleteInvestorMutation();
  const { showSnackbar } = useContext(SnackbarContext);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const userInfo = useUserInfo();

  const userIsVerified = userInfo.verificationStatus === "verified";
  const userIsDeleted =
    userInfo.pendingDeletion || deleteInvestorMutation.isSuccess;

  const requestDelete = () =>
    deleteInvestorMutation.mutate(void 0, {
      onSuccess: (response) => {
        setIsConfirmDialogOpen(false);
        showSnackbar({
          severity: "info",
          message: (
            <Translate
              translationKey={translationKeys.DELETE_SECURITY_CARD_SUCCESS}
            />
          ),
        });
        if (response.accountDisabled) {
          onLogout();
          window.location.reload();
        }
      },
      onError: (error) => {
        showSnackbar({
          severity: "error",
          message: <ErrorSnackbar error={error} />,
        });
      },
    });

  return (
    <>
      <InfoCard
        title={
          <Translate
            translationKey={translationKeys.DELETE_SECURITY_CARD_TITLE}
          />
        }
        subtitle={
          <Translate
            translationKey={
              userIsDeleted
                ? translationKeys.DELETE_SECURITY_CARD_CONFIRM_SUBTITLE_PENDING
                : translationKeys.DELETE_SECURITY_CARD_SUBTITLE
            }
          />
        }
        action={
          userIsDeleted ? null : (
            <WhmButton
              variant="outlined"
              color="error"
              onClick={() => setIsConfirmDialogOpen(true)}
              sx={{
                minWidth: "fit-content !important",
                svg: {
                  display: "block !important",
                },
                "&.MuiLoadingButton-root .MuiLoadingButton-loadingIndicator.MuiLoadingButton-loadingIndicatorCenter":
                  {
                    position: "absolute !important",
                  },
              }}
            >
              <Translate
                translationKey={translationKeys.DELETE_SECURITY_CARD_CTA}
              />
            </WhmButton>
          )
        }
        smallPadding
      />
      <WhmDialog open={isConfirmDialogOpen}>
        <WhmDialogTitle>
          <WhmTypography variant="h4">
            <Translate
              translationKey={
                translationKeys.DELETE_SECURITY_CARD_CONFIRM_TITLE
              }
            />
          </WhmTypography>
        </WhmDialogTitle>
        <WhmIconButton
          aria-label="close"
          onClick={() => setIsConfirmDialogOpen(false)}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <img src={closeIcon} alt="close" />
        </WhmIconButton>

        <WhmDialogContent>
          <WhmTypography
            variant="body1"
            sx={{ marginBottom: userIsVerified ? undefined : "8px" }}
          >
            <TranslateHtml
              translationKey={
                userIsVerified
                  ? translationKeys.DELETE_SECURITY_CARD_CONFIRM_SUBTITLE
                  : translationKeys.DELETE_SECURITY_CARD_PENDING_VERIFICATION_SUBTITLE
              }
            />
          </WhmTypography>
        </WhmDialogContent>
        {userIsVerified && (
          <WhmDialogActions>
            <WhmStack direction="row" justifyContent="flex-end" gap="8px">
              <WhmButton
                variant="outlined"
                color="secondary"
                disabled={deleteInvestorMutation.isPending}
                onClick={() => setIsConfirmDialogOpen(false)}
                sx={{
                  minWidth: "fit-content !important",
                }}
              >
                <Translate
                  translationKey={
                    translationKeys.DELETE_SECURITY_CARD_CONFIRM_CANCEL
                  }
                />
              </WhmButton>
              <WhmLoadingButton
                loading={deleteInvestorMutation.isPending}
                variant="contained"
                color="error"
                onClick={requestDelete}
                loadingPosition="start"
                sx={{
                  minWidth: "fit-content !important",
                  svg: {
                    display: "block !important",
                  },
                  "&.MuiLoadingButton-root .MuiLoadingButton-loadingIndicator.MuiLoadingButton-loadingIndicatorCenter":
                    {
                      position: "absolute !important",
                    },
                }}
              >
                <Translate
                  translationKey={
                    deleteInvestorMutation.isPending
                      ? translationKeys.DELETE_SECURITY_CARD_CONFIRM_CTA_PENDING
                      : translationKeys.DELETE_SECURITY_CARD_CONFIRM_CTA
                  }
                />
              </WhmLoadingButton>
            </WhmStack>
          </WhmDialogActions>
        )}
      </WhmDialog>
    </>
  );
}
