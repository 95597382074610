export const mobileShare = (value: string) => {
  const isMobile = !!navigator.userAgent.match(/iPhone|Android/i);
  if (isMobile) {
    void navigator
      .share({
        text: value,
      })
      .catch(() => {
        // Do nothing
      });
    return true;
  }

  return false;
};
