import React from "react";
import { WhmSkeleton, WhmStack, WhmTypography } from "@securitize/reactjs-whm";
import { Translate } from "../../Translation/Translate";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import { useResolvedPath } from "react-router";
import { useGetInvestmentProfileQuery } from "../../../data/useProfile";
import { InvestmentLoadingCard } from "./InvestmentLoadingCard";
import { SuitabilityCard } from "./suitability/SuitabilityCard";
import { AccreditationCard } from "./accreditation/AccreditationCard";
import { WhmResponsiveStack } from "../../shared/WhmResponsiveStack";
import { ErrorPage } from "../shared/ErrorPage";

export interface InvestmentProfileProps {
  isPendingActionsLoading: boolean;
}

export const InvestmentProfile = ({
  isPendingActionsLoading,
}: InvestmentProfileProps) => {
  const { pathname: currentPath } = useResolvedPath("");

  const investmentProfileQuery = useGetInvestmentProfileQuery();

  // Main UI
  if (investmentProfileQuery.isPending || isPendingActionsLoading)
    return (
      <>
        <WhmSkeleton
          data-test-id="profile-investment-profile-top-skeleton"
          sx={{ marginTop: "16px" }}
          variant="rounded"
          width={"100%"}
          height={52}
        />
        <WhmResponsiveStack
          gap="20px"
          sx={{
            alignItems: "baseline",
            marginTop: "20px",
          }}
        >
          <InvestmentLoadingCard />
          <InvestmentLoadingCard />
        </WhmResponsiveStack>
      </>
    );

  if (investmentProfileQuery.error)
    return (
      <ErrorPage
        errorCode={investmentProfileQuery.error.correlationId}
        onRefresh={investmentProfileQuery.refetch}
      />
    );

  return (
    <>
      <WhmStack sx={{ padding: "16px 0px" }}>
        <WhmTypography variant="h4">
          <Translate
            translationKey={TranslationKeys.PROFILE_INVESTMENT_PROFILE_TITLE}
          />
        </WhmTypography>
        <WhmTypography variant="body2" color="GrayText">
          <Translate
            translationKey={TranslationKeys.PROFILE_INVESTMENT_PROFILE_SUBTITLE}
          />
        </WhmTypography>

        <WhmResponsiveStack
          gap="20px"
          sx={{
            alignItems: "baseline",
            marginTop: "20px",
          }}
        >
          <SuitabilityCard
            investmentProfileData={investmentProfileQuery.data}
            currentPath={currentPath}
          />

          <AccreditationCard
            investmentProfileData={investmentProfileQuery.data}
            currentPath={currentPath}
          />
        </WhmResponsiveStack>
      </WhmStack>
    </>
  );
};
