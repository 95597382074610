import { InfoCard } from "../../shared/InfoCard";
import translationKeys from "../../../../contexts/I18nContext/translationKeys";
import { Translate } from "../../../Translation/Translate";
import { WhmLoadingButton } from "@securitize/reactjs-whm";
import { useDownloadInfoMutation } from "../../../../data/useProfile";
import { useContext } from "react";
import { SnackbarContext } from "../../../shared/Snackbar";
import ErrorSnackbar from "../../../shared/ErrorSnackbar";

export default function SettingsDownloadInfoCard() {
  const downloadInfoMutation = useDownloadInfoMutation();
  const { showSnackbar } = useContext(SnackbarContext);

  const requestDownloadData = () =>
    downloadInfoMutation.mutate(void 0, {
      onSuccess: () => {
        showSnackbar({
          severity: "info",
          message: (
            <Translate
              translationKey={
                translationKeys.DOWNLOAD_DATA_SECURITY_CARD_SUCCESS
              }
            />
          ),
        });
      },
      onError: (error) => {
        showSnackbar({
          severity: "error",
          message: <ErrorSnackbar error={error} />,
        });
      },
    });

  return (
    <InfoCard
      title={
        <Translate
          translationKey={translationKeys.DOWNLOAD_DATA_SECURITY_CARD_TITLE}
        />
      }
      subtitle={
        <Translate
          translationKey={translationKeys.DOWNLOAD_DATA_SECURITY_CARD_SUBTITLE}
        />
      }
      action={
        <WhmLoadingButton
          loading={downloadInfoMutation.isPending}
          variant="outlined"
          onClick={requestDownloadData}
          loadingPosition="start"
          sx={{
            minWidth: "fit-content !important",
            svg: {
              display: "block !important",
            },
            "&.MuiLoadingButton-root .MuiLoadingButton-loadingIndicator.MuiLoadingButton-loadingIndicatorCenter":
              {
                position: "absolute !important",
              },
          }}
        >
          <Translate
            translationKey={translationKeys.DOWNLOAD_DATA_SECURITY_CARD_CTA}
          />
        </WhmLoadingButton>
      }
      smallPadding
    />
  );
}
