import translationKeys from "../../contexts/I18nContext/translationKeys";
import { Translate, TranslateHtml } from "../Translation/Translate";
import {
  WhmButton,
  WhmStack,
  whmTheme,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { WhmResponsiveStack } from "./WhmResponsiveStack";
import { ReactComponent as InfoIcon } from "@securitize/assets-sec-ui/dist/icons/svg/info.svg";
import { ResponseError } from "../../data/types/responseError";

export default function ErrorSnackbar({ error }: { error?: ResponseError }) {
  return (
    <WhmStack gap="16px" direction="row">
      <WhmTypography
        sx={{
          fill: whmTheme.palette.common.white,
          marginTop: { xl: "2px" },
        }}
      >
        <InfoIcon fontSize="inherit" />
      </WhmTypography>
      <WhmResponsiveStack gap="8px" alignItems="center">
        <WhmTypography
          variant="body2"
          sx={{
            a: {
              textDecoration: "underline !important",
            },
          }}
        >
          <TranslateHtml
            translationKey={translationKeys.ERROR_HAS_OCCURRED}
            parameters={{
              correlationId: error?.correlationId ?? "",
            }}
          />
        </WhmTypography>
        {error?.correlationId && (
          <WhmButton
            variant="outlined"
            size="small"
            sx={{
              color: whmTheme.palette.common.white,
              borderColor: whmTheme.palette.common.white,
              width: { xs: "100%", xl: "auto" },
              minWidth: "fit-content !important",
              "&:hover": {
                borderColor: whmTheme.palette.common.white,
              },
            }}
            onClick={() => {
              navigator.clipboard
                .writeText(error.correlationId ?? "")
                .catch((e) => console.error(e));
            }}
          >
            <Translate translationKey={translationKeys.ERROR_COPY} />
          </WhmButton>
        )}
      </WhmResponsiveStack>
    </WhmStack>
  );
}
