import {
  WhmBox,
  WhmButton,
  WhmLink,
  WhmStack,
  WhmTextField,
  WhmTypography,
} from "@securitize/reactjs-whm";
import secLogoPositive from "@securitize/assets-sec-ui/dist/logos/svg/sec-trademark-positive.svg";
import { useFormikContext } from "formik";
import TranslationKeys from "../../contexts/I18nContext/translationKeys";
import { Translate } from "../Translation/Translate";
import { FormType } from "./types";
import config from "../../data/config";

export default function NameStep({ filler }: { filler?: React.ReactNode }) {
  const formik = useFormikContext<FormType>();

  return (
    <WhmStack sx={{ height: "100%" }} spacing={6} alignItems="center">
      <WhmBox
        component="img"
        sx={{ height: "24px" }}
        src={secLogoPositive}
        alt="Securitize Logo"
      />
      <WhmStack alignItems="center" sx={{ marginBottom: "8px !important" }}>
        <WhmTypography variant="h4">
          <Translate
            translationKey={TranslationKeys.REGISTRATION_STEP_NAME_TITLE}
          />
        </WhmTypography>
        <WhmTypography variant="body2" color="GrayText">
          <Translate
            translationKey={TranslationKeys.REGISTRATION_STEP_NAME_SUBTITLE}
          />
        </WhmTypography>
      </WhmStack>
      <WhmStack spacing={4} alignItems="center" sx={{ width: "100%" }}>
        <WhmTextField
          label={
            <Translate
              translationKey={TranslationKeys.REGISTRATION_FIRST_NAME}
            />
          }
          fullWidth
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <WhmTextField
          label={
            <Translate
              translationKey={TranslationKeys.REGISTRATION_LAST_NAME}
            />
          }
          fullWidth
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </WhmStack>
      {filler}
      <WhmStack spacing={4} sx={{ width: "100%" }}>
        <WhmButton type="submit" variant="contained" size="large" fullWidth>
          <Translate translationKey={TranslationKeys.REGISTRATION_NEXT} />
        </WhmButton>
        <WhmBox>
          <WhmTypography sx={{ textAlign: "center" }}>
            <Translate
              translationKey={TranslationKeys.REGISTRATION_ACCOUNT_EXISTS}
            />{" "}
            <WhmLink href={config.AUTH_LOGIN_URL} underline="hover">
              <Translate translationKey={TranslationKeys.REGISTRATION_LOGIN} />
            </WhmLink>
          </WhmTypography>
        </WhmBox>
      </WhmStack>
    </WhmStack>
  );
}
