import {
  WhmChip,
  WhmLoadingButton,
  WhmStack,
  whmTheme,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { ReactComponent as LaptopIcon } from "@securitize/assets-sec-ui/dist/icons/svg/laptop.svg";
import { ReactComponent as SmartphoneIcon } from "@securitize/assets-sec-ui/dist/icons/svg/smartphone.svg";
import React, { useContext, useState } from "react";
import { DeviceType, DeviceTypes } from "../../../../data/dtos/sessions.dto";
import { formatDateString } from "../../../../utils/dateUtils";
import TranslationKeys from "../../../../contexts/I18nContext/translationKeys";
import { Translate } from "../../../Translation/Translate";
import { I18nContext } from "../../../../contexts/I18nContext";

interface SignedInDeviceRowProps {
  name: string;
  lastAccess?: string;
  expires?: string;
  type: DeviceType;
  active: boolean;
  onDeleteSession?: () => void;
}

export const SignedInDeviceRow: React.FC<SignedInDeviceRowProps> = ({
  name,
  lastAccess,
  expires,
  type,
  active,
  onDeleteSession,
}) => {
  const { language } = useContext(I18nContext);
  const [isLogOutLoading, setIsLogOutLoading] = useState(false);

  const handleDeleteSession = () => {
    setIsLogOutLoading(true);
    onDeleteSession?.();
  };

  const lastAccessFormatted = lastAccess
    ? formatDateString(lastAccess, language)
    : "";
  const logOutFormatted = expires ? formatDateString(expires, language) : "";

  const iconStyle = { minWidth: "24px", marginRight: "4px" };

  return (
    <WhmStack
      direction="row"
      gap="8px"
      sx={{
        width: "100%",
        alignItems: "center",
      }}
    >
      {type === DeviceTypes.DESKTOP ? (
        <LaptopIcon
          width={24}
          height={24}
          fill={whmTheme.palette.action.active}
          style={iconStyle}
        />
      ) : (
        <SmartphoneIcon
          width={24}
          height={24}
          fill={whmTheme.palette.action.active}
          style={iconStyle}
        />
      )}

      <WhmStack flexGrow="1">
        <WhmTypography variant="body2" color={whmTheme.palette.text.primary}>
          {name}
        </WhmTypography>
        <WhmTypography variant="body2" color={whmTheme.palette.text.secondary}>
          {active ? (
            <Translate
              translationKey={
                TranslationKeys.PROFILE_SETTINGS_DEVICES_CARD_ACTIVE_SESSION
              }
            />
          ) : (
            <Translate
              translationKey={
                TranslationKeys.PROFILE_SETTINGS_DEVICES_CARD_LAST_ACCESS
              }
              replacements={{
                lastAccess: lastAccessFormatted,
                expires: logOutFormatted,
              }}
            />
          )}
        </WhmTypography>
      </WhmStack>

      {active ? (
        <WhmChip
          variant="filled"
          color="default"
          size="medium"
          label={
            <Translate
              translationKey={
                TranslationKeys.PROFILE_SETTINGS_DEVICES_CARD_THIS_DEVICE
              }
            />
          }
        />
      ) : (
        <WhmLoadingButton
          variant="outlined"
          size="medium"
          color="primary"
          loadingPosition="start"
          loading={isLogOutLoading}
          sx={{
            minWidth: "fit-content !important",
            svg: {
              display: "block !important",
            },
          }}
          onClick={handleDeleteSession}
        >
          <Translate
            translationKey={
              isLogOutLoading
                ? TranslationKeys.PROFILE_SETTINGS_DEVICES_CARD_LOGGING_OUT_CTA
                : TranslationKeys.PROFILE_SETTINGS_DEVICES_CARD_LOG_OUT_CTA
            }
          />
        </WhmLoadingButton>
      )}
    </WhmStack>
  );
};
