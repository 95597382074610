import { Theme, useMediaQuery } from "@securitize/reactjs-whm";
import SettingsDesktop from "./components/SettingsDesktop";
import SettingsMobile from "./components/SettingsMobile";
import { useContext } from "react";
import { ProfileContext } from "../../../contexts/ProfileContext";

interface SettingsProps {
  isPendingActionsLoading: boolean;
}

export default function Settings({ isPendingActionsLoading }: SettingsProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl"),
  );

  const contextValues = useContext(ProfileContext);

  const props = {
    isLoading: isPendingActionsLoading || contextValues.sessions.isLoading,
    ...contextValues,
  };

  return isMobile ? (
    <SettingsMobile {...props} />
  ) : (
    <SettingsDesktop {...props} />
  );
}
