import React, { useContext } from "react";
import { WhmStack } from "@securitize/reactjs-whm";
import { LoadingCard } from "./LoadingSkeletonCard";
import { useResolvedPath } from "react-router";
import { I18nContext } from "../../../contexts/I18nContext";
import { useGetInvestorInformationQuery } from "../../../data/useProfile";
import { VerificationNeeded } from "./VerificationNeeded";
import { PersonalDetailsCard } from "./PersonalDetailsCard";
import { AddressCard } from "./AddressCard";
import { TaxJurisdictionCard } from "./TaxJurisdictionCard";
import {
  InvestorTypes,
  VerificationStatuses,
} from "../../../data/types/investors";
import { EntityInformationCard } from "./entity/EntityInformationCard";
import { BusinessInformationCard } from "./entity/BusinessInformationCard";
import { ErrorPage } from "../shared/ErrorPage";

export interface InformationProps {
  isPendingActionsLoading: boolean;
}

export const Information = ({ isPendingActionsLoading }: InformationProps) => {
  const { pathname: currentPath } = useResolvedPath("");

  const { language } = useContext(I18nContext);
  const investorInfoQuery = useGetInvestorInformationQuery();
  const isEntity =
    investorInfoQuery.data?.investorType === InvestorTypes.ENTITY;

  if (investorInfoQuery.isPending || isPendingActionsLoading)
    return (
      <>
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
      </>
    );

  if (investorInfoQuery.error) {
    return (
      <ErrorPage
        errorCode={investorInfoQuery.error.correlationId}
        onRefresh={investorInfoQuery.refetch}
      />
    );
  }

  if (
    investorInfoQuery.data.verification.status === VerificationStatuses.NONE
  ) {
    return (
      <VerificationNeeded
        currentPath={currentPath}
        investorType={investorInfoQuery.data.investorType}
        entityName={investorInfoQuery.data.details.entityName}
      />
    );
  }

  return (
    <>
      <WhmStack sx={{ padding: "16px 0px" }}>
        {isEntity ? (
          <WhmStack gap="20px" flex="1">
            <EntityInformationCard investorInfoQuery={investorInfoQuery} />
            <BusinessInformationCard investorInfoQuery={investorInfoQuery} />
            <AddressCard
              investorInfoQuery={investorInfoQuery}
              language={language}
            />
          </WhmStack>
        ) : (
          <WhmStack gap="20px" flex="1">
            <PersonalDetailsCard investorInfoQuery={investorInfoQuery} />
            <AddressCard
              investorInfoQuery={investorInfoQuery}
              language={language}
            />
            <TaxJurisdictionCard
              investorInfoQuery={investorInfoQuery}
              language={language}
            />
          </WhmStack>
        )}
      </WhmStack>
    </>
  );
};
