import TranslationKeys, {
  TranslationKey,
} from "../../../../contexts/I18nContext/translationKeys";
import {
  InvestorEntityType,
  InvestorEntityTypes,
} from "../../../../data/dtos/investor.dto";

export const entityTypesTranslations: Record<
  InvestorEntityType,
  TranslationKey
> = {
  [InvestorEntityTypes.REVOCABLE_TRUST]:
    TranslationKeys.PROFILE_ENTITY_TYPES_REVOCABLE_TRUST,
  [InvestorEntityTypes.IRREVOCABLE_TRUST]:
    TranslationKeys.PROFILE_ENTITY_TYPES_IRREVOCABLE_TRUST,
  [InvestorEntityTypes.LIMITED_GENERAL_PARTNERSHIP]:
    TranslationKeys.PROFILE_ENTITY_TYPES_LIMITED_GENERAL_PARTNERSHIP,
  [InvestorEntityTypes.JOINT_ACCOUNT]:
    TranslationKeys.PROFILE_ENTITY_TYPES_JOINT_ACCOUNT,
  [InvestorEntityTypes.NON_PROFIT_FOUNDATION]:
    TranslationKeys.PROFILE_ENTITY_TYPES_NON_PROFIT_FOUNDATION,
  [InvestorEntityTypes.FOR_PROFIT_FOUNDATION]:
    TranslationKeys.PROFILE_ENTITY_TYPES_FOR_PROFIT_FOUNDATION,
  [InvestorEntityTypes.FUND]: TranslationKeys.PROFILE_ENTITY_TYPES_FUND,
  [InvestorEntityTypes.LLC]: TranslationKeys.PROFILE_ENTITY_TYPES_LLC,
  [InvestorEntityTypes.CORPORATION]:
    TranslationKeys.PROFILE_ENTITY_TYPES_CORPORATION,
  [InvestorEntityTypes.OTHER]: TranslationKeys.PROFILE_ENTITY_TYPES_OTHER,
};
