import { Phone, PhoneValidationStep } from "./types";
import { useState } from "react";
import { Theme, useMediaQuery, WhmDialog } from "@securitize/reactjs-whm";
import PhoneStep from "./PhoneStep";
import CodeStep from "./CodeStep";
import Snackbar, { SnackbarProvider } from "../shared/Snackbar";

export interface PhoneVerificationModalProps {
  countryCode: string;
  phone?: Phone;
  onVerify: () => void;
  open: boolean;
}

export default function PhoneVerificationModal({
  countryCode,
  phone,
  onVerify,
  open,
}: PhoneVerificationModalProps) {
  const mobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl"),
  );

  const [step, setStep] = useState<PhoneValidationStep>("phone");
  const [phoneState, setPhoneState] = useState<Phone>(
    phone || { code: "", number: "", fullNumber: "" },
  );

  const onCloseOverride = (_: Object, reason: string) => {
    if (reason !== "backdropClick") {
      // Do not close
    }
  };

  return (
    /* This ref is used to mount the modal in this same dom tree
       Using it as a container for the WhmDialog, we avoid the modal to be loaded in its own root component. */
    <div>
      <SnackbarProvider>
        <WhmDialog
          disablePortal={true}
          open={open}
          onClose={onCloseOverride}
          maxWidth={false}
          fullScreen={mobileOrTablet}
        >
          <div
            style={{
              display: step === "phone" ? "block" : "none",
              height: "100%",
            }}
          >
            <PhoneStep
              phone={phone}
              countryCode={countryCode}
              setStep={setStep}
              setPhone={setPhoneState}
            />
          </div>
          {step === "code" && (
            <CodeStep
              setStep={setStep}
              onVerify={onVerify}
              phone={phoneState}
            />
          )}
        </WhmDialog>

        <Snackbar />
      </SnackbarProvider>
    </div>
  );
}
