export const PORTFOLIO_PATH = "/portfolio";

export const tabRoutes = {
  information: "information",
  taxCenter: "tax-center",
  taxForm: "tax-center/tax-form",
  investmentProfile: "investment-profile",
  settings: "settings",
  authorizedMembers: "authorized-members",
};

export const profilePaths = (basePath: string) => ({
  home: `${basePath}/${tabRoutes.information}`,
  information: `${basePath}/${tabRoutes.information}`,
  taxCenter: `${basePath}/${tabRoutes.taxCenter}`,
  taxForm: `${basePath}/${tabRoutes.taxForm}`,
  investmentProfile: `${basePath}/${tabRoutes.investmentProfile}`,
  settings: `${basePath}/${tabRoutes.settings}`,
  authorizedMembers: `${basePath}/${tabRoutes.authorizedMembers}`,
});

export const tabRoutesToProfilePaths = (
  basePath: string,
): Record<string, string> => ({
  [tabRoutes.information]: profilePaths(basePath).information,
  [tabRoutes.taxCenter]: profilePaths(basePath).taxCenter,
  [tabRoutes.taxForm]: profilePaths(basePath).taxForm,
  [tabRoutes.investmentProfile]: profilePaths(basePath).investmentProfile,
  [tabRoutes.settings]: profilePaths(basePath).settings,
  [tabRoutes.authorizedMembers]: profilePaths(basePath).authorizedMembers,
});

export const INVESTMENT_PROFILE_URL = (redirectUrl: string): string => {
  const search = new URLSearchParams({ redirectUrl });
  return `/profile/suitability?${search.toString()}`;
};
export const VERIFICATION_URL = (redirectUrl: string): string => {
  const search = new URLSearchParams({ redirectUrl });
  return `/profile/verification/identity-verification?${search.toString()}`;
};
export const ACCREDITATION_URL = (redirectUrl: string): string => {
  const search = new URLSearchParams({ redirectUrl });
  return `/profile/accreditation?${search.toString()}`;
};
