export class ResponseError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }

  get correlationId() {
    return (
      this.response.headers.get("X-Correlation-Id")?.slice(-8) || undefined
    );
  }
}
