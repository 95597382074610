import React from "react";
import { InfoCard } from "../shared/InfoCard";
import { WhmChip } from "@securitize/reactjs-whm";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import { Translate } from "../../Translation/Translate";
import {
  SuitabilityStatus,
  SuitabilityStatuses,
} from "../../../data/dtos/investment-profile.dto";

const chipStatusColorMap: Record<
  SuitabilityStatus,
  "success" | "warning" | "error"
> = {
  [SuitabilityStatuses.PROCESSING]: "warning",
  [SuitabilityStatuses.VERIFIED]: "success",
  [SuitabilityStatuses.NOT_APPROVED]: "error",
  [SuitabilityStatuses.NONE]: "success",
};

const chipStatusTextMap: Record<SuitabilityStatus, React.ReactNode> = {
  [SuitabilityStatuses.PROCESSING]: (
    <Translate
      translationKey={TranslationKeys.INVESTMENT_PROFILE_STATUS_IN_REVIEW}
    />
  ),
  [SuitabilityStatuses.VERIFIED]: (
    <Translate
      translationKey={TranslationKeys.INVESTMENT_PROFILE_STATUS_COMPLETED}
    />
  ),
  [SuitabilityStatuses.NOT_APPROVED]: (
    <Translate
      translationKey={TranslationKeys.INVESTMENT_PROFILE_STATUS_REJECTED}
    />
  ),
  [SuitabilityStatuses.NONE]: undefined,
};

interface ProfileCardProps {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  suitabilityStatus?: SuitabilityStatus;
  button?: React.ReactNode;
  alert?: React.ReactNode;
}

export const InvestmentProfileTabCard: React.FC<ProfileCardProps> = ({
  title,
  subtitle,
  suitabilityStatus,
  button,
  alert,
}) => {
  return (
    <InfoCard title={title} subtitle={subtitle} smallPadding>
      {suitabilityStatus && suitabilityStatus !== SuitabilityStatuses.NONE && (
        <WhmChip
          variant={"filled"}
          label={chipStatusTextMap[suitabilityStatus]}
          color={chipStatusColorMap[suitabilityStatus]}
          sx={{ marginTop: "12px" }}
        />
      )}
      {alert}
      {button}
    </InfoCard>
  );
};
