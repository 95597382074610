import { WhmStack } from "@securitize/reactjs-whm";
import { InfoCard } from "../../shared/InfoCard";
import React from "react";
import { SignedInDeviceRow } from "./SignedInDeviceRow";
import { Session, SessionAgent } from "../../../../data/dtos/sessions.dto";
import TranslationKeys from "../../../../contexts/I18nContext/translationKeys";
import { Translate } from "../../../Translation/Translate";

interface SettingsDevicesCardProps {
  userSessions?: Session[];
  activeUserSession?: SessionAgent;
  onDeleteSession: (id: string) => void;
}

export const SettingsDevicesCard: React.FC<
  React.PropsWithChildren<SettingsDevicesCardProps>
> = ({ userSessions, activeUserSession, onDeleteSession }) => {
  return (
    <InfoCard
      title={
        <Translate
          translationKey={TranslationKeys.PROFILE_SETTINGS_DEVICES_CARD_TITLE}
        />
      }
      subtitle={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_SETTINGS_DEVICES_CARD_SUBTITLE
          }
        />
      }
      smallPadding
    >
      <WhmStack gap="16px" marginTop="24px" marginBottom="8px">
        {activeUserSession && (
          <SignedInDeviceRow
            name={activeUserSession.os}
            type={activeUserSession.deviceType}
            active={true}
          />
        )}
        {userSessions &&
          userSessions.map((session) => (
            <SignedInDeviceRow
              name={session.agent.os}
              key={session.sessionId}
              type={session.agent.deviceType}
              lastAccess={session.lastActive}
              expires={session.expires}
              active={false}
              onDeleteSession={() => onDeleteSession(session.sessionId)}
            />
          ))}
      </WhmStack>
    </InfoCard>
  );
};
