import { UserDto } from "./user.dto";
import {
  AccountStatus,
  AccreditationStatus,
  BrokerDealerVerificationStatus,
  Gender,
  InvestorType,
  RiskLevel,
  VerificationLevel,
  VerificationStatus,
  VerificationSubStatus,
} from "../types/investors";

export const InvestorEntityTypes = {
  REVOCABLE_TRUST: "revocable-trust",
  IRREVOCABLE_TRUST: "irrevocable-trust",
  LIMITED_GENERAL_PARTNERSHIP: "limited-partnership/general-partnership",
  JOINT_ACCOUNT: "joint-account",
  NON_PROFIT_FOUNDATION: "non-profit-foundation",
  FOR_PROFIT_FOUNDATION: "for-profit-foundation",
  FUND: "fund",
  LLC: "llc",
  CORPORATION: "corporation",
  OTHER: "other",
} as const;
export type InvestorEntityType =
  (typeof InvestorEntityTypes)[keyof typeof InvestorEntityTypes];

export declare class TaxInfo {
  taxId: string;
  taxCountryCode: string;
}

export declare class InvestorDetails {
  riskLevel: RiskLevel;
  netWorth: number;
  annualIncome: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  entityName?: string;
  birthday?: Date;
  incorporationDate?: Date;
  website?: string;
  comment?: string;
  birthCountry?: string;
  birthCity?: string;
  birthState?: string;
  mainIdentificationNumber?: string;
  businesses?: string;
  sourceOfFunds?: string;
  lineOfBusiness?: string;
  leiCode?: string;
  entityType?: InvestorEntityType;
  entityIdNumber?: string;
  phone: {
    isVerified: boolean;
    code: string;
    number: string;
    fullNumber: string;
  };
  address?: {
    street?: string;
    houseNumber?: string;
    entrance?: string;
    city?: string;
    countryCode: string;
    state?: string;
    zip?: string;
  };
  gender?: Gender;
  tax: TaxInfo[];
  largeTraderId?: string;
}
export declare class BrokerDealerInformation {
  id?: number;
  verificationStatus?: BrokerDealerVerificationStatus;
  verificationStatusComment?: string;
}
export declare class AnalyticsInformation {
  enabled: boolean;
  mixPanelId?: string;
}
export declare class VerificationInformation {
  level: VerificationLevel;
  status: VerificationStatus;
  subStatus: VerificationSubStatus;
  updateDate: Date;
  errors?: Array<string>;
  allErrors: Array<string>;
}
export declare class AccreditationInformation {
  status?: AccreditationStatus;
  statusComment?: string;
  statusChangeDate?: Date;
  id?: string;
}
export declare class AccountInformation {
  status?: AccountStatus;
  euStatus?: AccountStatus;
  statusSignature?: string;
  euStatusSignature?: string;
}
export declare class InvestorDto {
  id: string;
  investorType: InvestorType;
  email: string;
  contactEmail: string;
  displayName: string;
  isHidden: boolean;
  createdDate: Date;
  details: InvestorDetails;
  brokerDealer: BrokerDealerInformation;
  analytics: AnalyticsInformation;
  verification: VerificationInformation;
  accreditation: AccreditationInformation;
  account: AccountInformation;
  pendingDeletion?: boolean;
  deletionDeclineDate?: Date;
  lastLoggedIn?: Date;
  user?: UserDto | null;
}
export {};
